import React, {Component} from 'react';
import { injectStripe, PaymentRequestButtonElement} from 'react-stripe-elements';

import PropTypes from 'prop-types';

class StripeCheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);

    const paymentRequest = props.stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Demo total',
          amount: props.paymentTotal || 100,
        },
        requestPayerName: true,
        requestPayerEmail: true
      });
  
      paymentRequest.on('token', ({complete, token, ...data}) => {
        complete('success');

        const { submitPayment } = this.props;
        submitPayment('token', {token: token.id, last4: token.card.last4});
      });
  
      paymentRequest.canMakePayment().then((result) => {
        this.setState({canMakePayment: !!result});
      });
  
      this.state = {
        canMakePayment: false,
        paymentRequest,
      };
  }

  async submit(ev) {
    // User clicked submit


  }

  render() {
    const { paymentRequest } = this.state;
    return this.state.canMakePayment ? (<div className="checkout">
        <PaymentRequestButtonElement  paymentRequest={paymentRequest}></PaymentRequestButtonElement>
      </div>
    ) :null;
  }
}


StripeCheckoutForm.propTypes = {
  submitPayment: PropTypes.func
}

export default injectStripe(StripeCheckoutForm);