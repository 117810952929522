import *  as moment from 'moment';
import React from 'react';
import { HtmlMessageComponent } from '../components/html-message.component';
import RatingWidget from '../components/widgets/rating.widget.component';
import { InlineUserInput } from '../components/inline-user-input.component';

export function howManyApplications(endOn, frequency = 'monthly') {
    let mod = frequency;
    switch(frequency) {
        case 'daily':
            mod = 1;
            break;
        case 'bi-weekly':
            mod = 3.5;
            break;
        case 'weekly':
            mod = 7;
            break;
        case 'bi-monthly':
            mod  = 14;
            break;
        case 'monthly':
            mod = 30;
            break;
        default: 
    }
    const today = moment();
    const endProgram = moment(endOn);
    const daysLeft = endProgram.diff(today, 'days');

    if (daysLeft < 0) return 0;
    return Math.floor(daysLeft / mod) + 1;
}

export function populateQuestion(questions) {
    let qobject = {}
        if (!questions.length) {
            qobject = {
                current_question: null,
                current_type: null,
                current_options: null,
                current_messages: null,
                next: false
            }
        } else {
            const q = questions.shift();
            qobject = {
                current_question: q.question,
                current_type: q.type,
                current_options: q.options,
                current_messages: q.followup
            }
            if (!questions.length) {
                qobject.next = false;
            } else {
                qobject.next = true;
            }
        }
    return qobject;
}

export function clearModalPriceAlerts() {
    //$("#price-breakdown").html('');
}

export function createModalProgramPricingAlert(programInfo) {
   const html =  `<div class="alert alert-secondary" role="alert">
    <div class="action-buttons"><button class="circle plus" style="display:none;"></button>
                <button class="circle minus" ></button>
            </div>
        <h4 class="program-name">Program Name</h4>
        <div class="program-description">Description of program
                <span class="program-cost">$500</span>
        </div>
</div>`;
return html;
// $("#price-breakdown").append(html);
}


  export function generateStepTrigger(program, self) {
      const questions = program.probing_questions || [];
      const steps = [];
      if(!questions) return [];
      questions.forEach(q => {
            const conditionals = q.conditionals || [];

            if (q.component) {
                switch(q.component.type) {
                    case 'html':
                        const html = q.component.html;
                        let newc = (<HtmlMessageComponent />);
                        q.component = newc;
                        q.inputAttributes = {html: html};
                        break;
                    case 'rating':
                        const placeholder = q.component.placeholder || 3;
                        let newcc= (<RatingWidget placeholder={placeholder} />);
                        q.component = newcc;
                        q.trigger = (payload) => {
                            const val = payload.value;
                    
                              if (val !== "5") {
                                return  '9orless-lawn';
                              } else {
                                return '10-lawn';
                              }
                          }
                        break;
                    case 'userinput': 
                        let ph = q.component.placeholder || 'Enter Input';
                        let newinput = (<InlineUserInput placeholder={ph} />);
                        q.component = newinput;
                        break;
                    default:
                }
            }
           
            let trigger = !conditionals.length ? q.trigger : null;
            if (!trigger) {
                trigger = ({value}) => {
                    const next = conditionals.filter(i => i.matches === value);
                    if (next.length) {
                        if(typeof q.setVariable !== 'undefined') {
                            self.answers[q.setVariable] = value;
                            const payload = {}
                            payload[q.setVariable] = value
                            self.recordChatRecord({answers: payload});
                        }
                        return next[0].trigger;
                    } else {
                        return 'handle-error';
                    }
                };
            }

            if (q.options) {
                q.options = q.options.map(p => {
                    if (typeof p === 'object') {
                        return {...p, trigger: ({value}) => {
                            if(typeof q.setVariable !== 'undefined') {
                                self.answers[q.setVariable] = value;
                                const payload = {}
                                payload[q.setVariable] = value
                                self.recordChatRecord({answers: payload});

                            }
                            return trigger;
                        }};
                    }
                    return {value: q, label: q, trigger: ({value}) => {
                        if(typeof q.setVariable !== 'undefined') {
                            self.answers[q.setVariable] = value;
                            const payload = {}
                            payload[q.setVariable] = value
                            self.recordChatRecord({answers: payload});
                            // self.recordChatRecord({answers: JSON.stringify(self.answers)});
                        }
                        return trigger;
                    }};
                })
            } else {
                q.trigger = ({value}) => {
                    if(typeof q.setVariable !== 'undefined') {
                        self.answers[q.setVariable] = value;
                        const payload = {}
                        payload[q.setVariable] = value
                        self.recordChatRecord({answers: payload});
                        // self.recordChatRecord({answers: JSON.stringify(self.answers)});
                    }
                    return trigger;
                };
            }
      
            steps.push(q);
      });
      return steps;
  }