import React from 'react';
import { HtmlMessageComponent } from '../components/html-message.component';
import { CalculatingQuoteComponent } from '../components/calculating.component';

export default function gettingStarted(self) {
    return [
      {
        id: 'get-started',
        component: (<CalculatingQuoteComponent setPriceBlocks={self.setPriceBlocks} apiURL={self.apiURL} selectedServices={self.state.selectedServices} answers={self.answers} 
              ga={self.ga} companyId={self.companyId} botId={self.botId} bot={self._bot.current} totalLotSize={self.state.totalLotSize} adjustedLotSize={self.state.adjustedLotSize} chatId={self.state.chatId} customerZip={self.state.customerZip +''} />),
        asMessage: false,
        waitAction: true,
        dispatchMessage: () => {
          self._modal.current.handleOpen();
        } 
      },
        {id: 'thanks',
        message: 'Thank you so much for buying',
        end: true}
      ];
}