// http://localhost:3000/8d8bade6-51de-4dc3-9d15-0370c97e9521/d37685ff-d29e-47f7-bc6d-88c81050665d?phonenumber=6163295025&source=contactForm&customerName=Erik%20Alburg&customerEmail=erik.alburg@gmail.com&customerAddress=5915%20Castle%20Brook%20Ave%20SE&customerCity=Kentwood&customerState=MI&customerZip=49508

import React, {Component} from 'react';
import './App.css';

import ChatBot from 'react-simple-chatbot';
import styled from 'styled-components';

import { generateStepTrigger } from './utils/utils';
import * as fetch from 'fetch';
import { ProgramSelectComponent } from './components/program-select.component';
import { ServiceBotHeader } from './components/service-bot-header.component';
import SimpleModal from './components/quote/quote.modal.component';

import {StripeProvider} from 'react-stripe-elements';
import { generateLawnServiceSteps } from './steps/lawn-services';
import welcomeSteps from './steps/welcome'
import gettingStarted from './utils/getting-started';
import newCustomerOnboard from './steps/new-customer';
import {generateServiceQuestionsSteps} from './steps/after-purchase-questions';
import { generateObjectionSteps} from './steps/objections';
import SqFtSlider from './components/widgets/slider.widget.component';
import LoadingScreen from './components/loading/loading-screen'
import { Desktop, Mobile, Tablet } from './utils/media-queries';
import { CalculatingQuoteComponent } from './components/calculating.component';

import media from './components/loading/media.conf';
import { generatePestServiceSteps } from './steps/pest-services';

import * as parser from 'parse-address';
import * as na_parser  from 'parse-address-string';
import * as _ from 'lodash';
import { generateIrrigationServiceQuestions } from './steps/irrigation-services';
import MeasureItModal  from './components/modals/measur.it.modal';
import { HtmlMessageComponent } from './components/html-message.component';
import { translateState } from './utils/us-states';

const ChatContainer = styled.div`

  ${media.largest`
      height: 100vh; 
      backgroundColor: white; 
      width: 50%; 
      margin: auto;
      overflow: hidden;
      & div.chatbot {
        width: 103.5%;
      };
  `}

  ${media.desktop`
    height: 100vh; 
    backgroundColor: white; 
    width: 50%; 
    margin: auto;
    overflow: hidden;
    & div.chatbot {
      width: 103.5%;
    };
  `}

  ${media.tablet`
    height: 98vh; 
    backgroundColor: white; 
    width: 100%; 
    margin: auto; 
    paddingBottom: 80px;
    & div.chatbot {
      width: 103%;
    };
  `}

  ${media.phone`
    height: 98vh;
    backgroundColor: white;
    width: 100%;
    margin: auto;
  `}
`



class App extends Component {

  currentQuestion = {}
  // apiURL = 'https://zs4p00xu6b.execute-api.us-east-2.amazonaws.com/staging';
  // apiURL = 'http://localhost:3005';
  apiURL = "https://api.lawnbot.biz";

  dashboardApi = `https://5gj2wrdfa5.execute-api.us-east-1.amazonaws.com/prod`
  // dashboardApi = `http://localhost:3006`

  // company info
  companyId = ''
  botId = ''
  companyName = ''
  instaQuoteMessage = ''
  companyLogo = ''
  companyAvatar = ''
  companyTagLine = ''
  botName = ''
  companyValueDescription = '';
  companyValueAddImage = '';

  learnMoreSteps = [];
  objectionSteps = [];
  serviceQuestionsSteps = [];

 // customer info
  pricingOption = 'monthly';
  totalLotSize = 5000;
  customerName = 'Erik Alburg';

  answers = {};

  companyMinQuotableSize = 500;

  bubbleStyle = {
    boxShadow: '0 1px 28px 0 rgba(90,60,122,.22)',
    minWidth: 42,
    borderRadius: 30,
    backgroundColor: 'rgb(228, 228, 235)',
    boxSizing: 'content-box',
    minHeight: '1.35em',
    display: 'inline-block',
    maxWidth: '82%',
    overflow: 'hidden',
    padding: '16px 24px',
    transition: 'opacity .5s,background-color .75s,color .75s',
    color: 'black',
    marginBottom: 35,
    marginRight:'10%',
    fontFamily: `'Open Sans', sans-serif`
    }  

    desktopStyle = {height: '100vh', backgroundColor: 'white', width: '50%', margin: 'auto'}
    mobileStyle = {height: '98vh', backgroundColor: 'white', width: '100%', margin: 'auto'}
    tabletStyle = {height: '98vh', backgroundColor: 'white', width: '70%', margin: 'auto', paddingBottom: 80};
    overFlowHiddenStyle = {overflow: 'hidden'};

programSteps = []

priceBlocks= [];

availableServices = [ 

];

selectedServices = [];
activatedServices = [];

dsteps = [];

ga = null;

redirectToPortal = false;
  

  startSericeQuestions() {
    const { selectedPrograms, steps } = this.state;

    if (selectedPrograms.length) {
      const clonePrograms = [...selectedPrograms];
      let nextEntry = 'get-started';
      for( let p = 0; p < clonePrograms.length; p++) {
        const program = selectedPrograms.shift();
        const entry = this.getSericeEntryId(program) + '-entry';
    
        const foundStep = steps.find(s => s.id === entry);
        // console.log(entry, foundStep);
        
        if (foundStep) {
          this.setState({selectedPrograms});
          nextEntry = entry;
          break;
        }
      }

      if (nextEntry === 'get-started') {
        // console.log('last question here');
        this.updateCalculatingStep();
        return 'next-service';
      }
      return nextEntry;
    } else {
      const t = this.updateCalculatingStep();
      return t;
     
    }
  }

  getSericeEntryId(program) {
    let entrypoint = '';
    switch(program) {
      case 'Fertilizer & Weed Control':
        entrypoint = 'fertnweed';
        break;
      case 'Mosquito Control':
        entrypoint = 'mosquito';
        break;
      case "Mowing":
        entrypoint = 'mowing';
        break;
      case "Lawn Seeding":
        entrypoint = 'lawn-seeding';
        break;
      case "Irrigation Services":
        entrypoint = 'irrigation';
        break;
      case "Perimeter Pest Control":
        entrypoint = "perimeter-pest-control";
        break;
      case "Pest Control":
        entrypoint = "pest-control";
        break;
      case "Tick":
        entrypoint = "tick";
        break;
      case "Mosquito & Tick":
        entrypoint = "mosquito-and-tick";
        break;
      case "Flea & Tick":
        entrypoint = "flea-and-tick";
        break;
      case "Flea & Ant":
        entrypoint = "flea-and-ant";
          break;
      case "Covid":
        entrypoint = "covid";
        break;
      case "Snow Removal":
        entrypoint = "snow";
          break;
      case "Aeration":
        entrypoint = "aeration";
          break;
      case "Aeration & Overseed":
        entrypoint = "aeration-and-overseed";
        break;
      case "Liquid Aeration":
        entrypoint = "liquid-aeration";
        break;
      case "Army Worm":
        entrypoint = "army-worm";
        break;
      case "Fire Ant":
        entrypoint = "fire-ant";
        break;
      case "Air 8":
        entrypoint = "air-8";
        break;
      case "Yard Clean Up":
        entrypoint = "yard-clean-up";
        break;
      case "Spotted Lantern Fly":
        entrypoint = "spotted-lantern-fly";
        break;
      case "Thatch":
        entrypoint = "thatch";
        break;
      case "Mole Control":
        entrypoint = "mole-control";
        break;
      case "Bin Cleaning":
        entrypoint = "bin-cleaning";
        break;
      default:
    }

    return entrypoint;
  }
  

  constructor(props) {
    super(props);

    this.state = {
                      steps: [],
                      priceBlocks: [],
                      term: 'application',
                      availableServices: this.availableServices,
                      allTotal: 0,
                      firstTotal: 0,
                      regularTotal: 0,
                      discout: 0,
                      modalOpen: false,
                      companyLoading: true,
                      loading: true,
                      loadedPriceBlocks: false,
                      noPII: true,
                      chatStyle: this.desktopStyle,
                      deviceType: 'desktop',
                      overflowStyle: this.overFlowHiddenStyle,
                      customerRecord : null,
                      customerLat : 0,
                      customerLng : 0,
                      customerAddress : "",
                      customerCity : "",
                      customerState : "",
                      customerZip : '',
                      phonenumber : null,
                      customerName: null,
                      customerEmail: '',
                      source: null,
                      streetImg: null,
                      aerialImg :null,
                      chatId: null,
                      charging: false,
                      estimateModalOpened: false,
                      measuritImg: null,
                      crmId: null,
                      paymentToken: null
           };

    this.updatePriceBlocks = this.updatePriceBlocks.bind(this);
    this.updatePricingTerms = this.updatePricingTerms.bind(this);
    this.submitPayment = this.submitPayment.bind(this);
    this.lookupCustomer = this.lookupCustomer.bind(this);
    this.setPriceBlocks = this.setPriceBlocks.bind(this);
    this.thinkAboutIt = this.thinkAboutIt.bind(this);
    this.onMessage = this.onMessage.bind(this);
    

    this._bot = React.createRef();
    this._modal = React.createRef();
    this._measureit = React.createRef();
    const s = this;
    // setTimeout(() => {
    //   s._modal.current.handleOpen();
    // }, 6000)
    
    // this.lookupCompany();
}

compileSteps() {
  const steps = [
    ...welcomeSteps(this), 
    ...gettingStarted(this), 
    ...newCustomerOnboard(this), 
    ...this.programSteps];

    this.setState({steps});
}

lookupCustomer = (number = null) => {
if (number) {
  this.setState({phonenumber: number});
} else {
  if (this.state.source) {

  } else {
    number = this.state.phonenumber;
  }
  
}
 if(number) {
  fetch.fetchUrl(this.apiURL + `/company/${this.companyId}/customer/lookup?phonenumber=` + number, {}, (error, meta, body) => {
    const statusCode = meta.status;
    if (statusCode === 404) {
      console.log("no user found");
    } else {
      
      const str_body = body.toString();
      let jsonBody = {};
      try {
        
        jsonBody = JSON.parse(str_body);
        if (jsonBody.status === 200) {
          this.setState({noPII: false});
        }
        if (jsonBody.record) {
          const keys = Object.keys(jsonBody.record)
          if (keys.length) {
            this.setCustomerRecord(jsonBody).then( async () => {
              try {
                await this.startChatRecord();
               
                
              } catch (e) {
                console.log("we got a reject", e);
              }
              this.compileSteps();
              this.loadSteps();
            });
          }
        }
       
        
       
      } catch(e) {
        console.log('error tracking', e);
      }

    }
  });
 } else {
   const self = this;
   setTimeout(() => {
    // self.setState({estimateModalOpened: true});
    this.compileSteps();
    self.loadSteps();
    // this._measureit.current.handleOpen();
   }, 4000)
  
 }
}

lookupCompany () {
  return new Promise((res, rej) => {
    let path = window.location.pathname.replace(/[/\s]/, "");
  if (!path.length || path === 'index.html') {
    path = 'be26004d-14f8-4d84-bc96-9ccccc9d99c3/7465f865-1e9d-479e-a479-d2d054cb6463';
  }
  const [companyId, botId] = path.split("/");
  this.companyId = companyId;
  this.botId = botId;
  
  fetch.fetchUrl(this.apiURL + `/company/${companyId}/bot/${botId}`, {}, (error, meta, body) => {
    const statusCode = meta.status;
    if (statusCode === 404) {
      console.log("no company found");
    } else {
      const str_body = body.toString();
      let jsonBody = {};
      try {
        jsonBody = JSON.parse(str_body);
        this.isLive = jsonBody.isLive || false;
        this.creditCardPaymentType = jsonBody.paymentType || 'stripe';
        this.companyId = jsonBody.id;
        this.companyName = jsonBody.companyName;
        this.bypassPayment = jsonBody.bypassPayment || false;
        this.companyLogo = jsonBody.companyLogo;
        this.companyAvatar = jsonBody.companyAvatar;
        this.companyTagLine = jsonBody.tagLine;
        this.botName = jsonBody.botName;

        this.sqftEstimateSource = jsonBody.sqftEstimateSource || 'zillow';
        this.instaQuoteMessage = jsonBody.instaQuoteMessage;
        this.availableServices = jsonBody.availableServiceTypes;
        this.companyValueDescription = jsonBody.valueAddDescription;
        this.companyAboutUsVideo = jsonBody.aboutUsVideo;
        this.companyValueAddImage = jsonBody.valueAddImage;
        this.companyMinQuotableSize = typeof jsonBody.minQuotableSize !== 'undefined' ? jsonBody.minQuotableSize : 500;
        this.companyMaxQuotableSize = jsonBody.maxQuotableSize || 40000;
        this.serviceTax = jsonBody.serviceTax || 0.0;
        this.lookupZipCodeTax = jsonBody.lookupZipCodeTax || false;
        this.zipCodes = jsonBody.zipCodes || [];
        this.sqFtPercentage = this.sqftEstimateSource === 'zillow' ?  1 - (jsonBody.sqFtPercentage / 100) || .40 : 1.0;
        this.dontTaxZipCodes = jsonBody.dontTaxZipCodes;

        this.autoPayAvailable = jsonBody.autoPayAvailable === null || typeof jsonBody.autoPayAvailable === 'undefined'  ?  true : jsonBody.autoPayAvailable;
        this.prePayAvailable = jsonBody.prePayAvailable === null || typeof jsonBody.prePayAvailable === 'undefined' ?  true : jsonBody.prePayAvailable;

        /// handle status 8/9 client redirects
        this.isCurrentClient = false;
        this.currentClientRedirectUrl = null;

        let term = 'application';
        if (!this.autoPayAvailable && this.prePayAvailable) {
          term = 'total';
        }

        this.hideSummary = jsonBody.hideSummary === null ?  false : jsonBody.hideSummary;
        this.autoPayAlternativeLabel = jsonBody.autoPayAlternativeLabel || null;
        this.autoPayPriceMethod = jsonBody.autoPayPriceMethod || 'first-application-payment';
        this.autoPayMonthlyPricePeriod = jsonBody.autoPayMonthlyPricePeriod || 12;
        
        this.botQuoteAnalyzingGif = jsonBody.botQuoteAnalyzingGif || 'standard';
        this.botSpeed = jsonBody.botSpeed || 1750;
       
        this.quoteModalImage = jsonBody.quoteModalImage
        if (this.quoteModalImage.indexOf("?") !== -1 ) {
          this.quoteModalImage = this.quoteModalImage.split("?")[0];
        }
        this.learnMoreSteps = jsonBody.learnMoreSteps || [];
        this.objectionSteps = jsonBody.objectionSteps || generateObjectionSteps(this);
        this.serviceQuestionsSteps = jsonBody.serviceQuestionsSteps || generateServiceQuestionsSteps(this);
        this.probingQuestionsSteps = jsonBody.probingQuestions || generateLawnServiceSteps(this);
        this.preModalQuestions = jsonBody.preModalQuestions || [];

        this.termsOfService = jsonBody.termsOfService || null ;

        /// crm
        this.crmSolution = jsonBody.crmSolution || null;
        this.crmPortalUrl = jsonBody.crmPortalUrl || null;

        /// fallback
        this.zillowFallback = typeof jsonBody.zillowFallback === 'undefined' ? false : jsonBody.zillowFallback;
        
        /// get redirect values
        this.willRedirect = typeof jsonBody.willRedirect === 'undefined' ? false : jsonBody.willRedirect;
        this.redirectUrl = typeof jsonBody.redirectUrl === 'undefined' ? null : jsonBody.redirectUrl;
        this.purchaseMade = false;

        this.skipProbingQuestions = typeof jsonBody.skipProbingQuestions === "undefined" ? false: jsonBody.skipProbingQuestions;


        this.salutation = jsonBody.salutation || 'Welcome';
        this.greeting = jsonBody.greeting || null;


        this.isMetric = typeof jsonBody.isMetric === 'undefined' ? true : jsonBody.isMetric;
        this.measurementMode = typeof jsonBody.measurementMode === 'undefined' ? 'perimeter' : jsonBody.measurementMode;

        // this.setupAvailableServices();
        const self = this;
        // self.setState({companyLoading: false}, () => {
        //   this.lookupCustomer();
        // });
    
        this.setState({companyLoading: false, term}, () => {
          res();
          // setTimeout(() => {
          //   self._modal.current.handleOpen()
          // }, 5000)
        })
      } catch(e) {
        console.log('error tracking', e);
        this.setState({companyLoading: false});
        rej();
      }
    }
  });
  });
  
}

setupAvailableServices() {

 this.programSteps = [
  {id : 'intro-program',
  message: 'What services are you interested in today?',
  trigger: 'select-programs'},
  {id: 'select-programs',
  component: (<ProgramSelectComponent available_programs={this.availableServices} />),
  asMessage: false,
  waitAction: true,
  hideInput: true,
  dispatchMessage: (val) => {
    this.recordChatRecord({serviceTypes: val});
  },
  trigger: (val) => {
      return 'confirm-programs';
    
  }},
  {id: 'confirm-programs',
  message: ({previousValue, steps}) => {
    this.setState({selectedPrograms: [...previousValue], selectedServices: [...previousValue]});
    this.activatedServices = [...previousValue];
    return `You selected ${previousValue.join(', ')}!`;
  },
  trigger: (val) => {
    const { selectedPrograms } = this.state;
   
      const hasIrrigation = selectedPrograms.find(i => i === "Irrigation Services") ? true : false;
      const hasBinCleaning = selectedPrograms.find(i => i === "Bin Cleaning") ? true : false;
      const hasSqftServices = selectedPrograms.filter(i => i !== 'Irrigation Services' && i !== 'Bin Cleaning').length ? true : false;
      

      if (this.sqftEstimateSource === 'measur-it' && hasSqftServices) {
        // TODO - add measur.it flow
        
        return "measur-it-okay-great";
      } else if (hasIrrigation || hasBinCleaning) {
          const dest = this.startSericeQuestions();
          return dest;
      } 
      return 'ask-quote-now';
  }
  } ,
  {id: 'pre-modal-exit', message: 'Thanks for answering our questions', trigger: 'ask-quote-now'},
  {id: 'ask-quote-now', message: 'Would you like to get an Instant Quote now or customize your quote?',
  trigger: 'get-quote-now'},
  { id: 'open-quote-now', message: 'Please wait...', trigger: () => {
    const t = this.updateCalculatingStep();
      return 'no-questions-open-quote';
  }},
  { id: 'get-quote-now' , options: [
    {value: "get-quote", label: 'Get Quote Now', hideText: true,  trigger: () => {
      const t = this.updateCalculatingStep();
      return t;
      
    }}, 
    {value: 'customize-quote', label: 'Customize My Quote', hideText: true, trigger: () => {
      const dest = this.startSericeQuestions();
      return dest;
    }}],
  optionType: 'default' },
  {id: 'we-will-now-caculate', 
   message: "We will now recommend the best possible programs.",
  trigger: 'get-started' },
  {id: 'next-service',
   message: () => {
     const { selectedPrograms, steps } = this.state;

     if (selectedPrograms.length) {
      const clonePrograms = [...selectedPrograms];
      const found = [];
      const entries = clonePrograms
              .map(i =>  {
                return {name: i, id: this.getSericeEntryId(i) + '-entry'};
              })
              .map(i => {
                const entry = steps.find(p => p.id === i.id);
                if (entry) {
                  found.push(i.name);
                }
                return entry;
              })
              .filter(i => i);
      
      if (found.length) {
        return "Okay.... now let me ask you questions about " + found[0];
      } else {
        return "Thank you for answering our questions.";
      }

       
     } else {
       return "Thank you for answering our questions.";
     }
   },
    trigger: () => {
      const { selectedPrograms, steps } = this.state;
      if (selectedPrograms.length) {

        const clonePrograms = [...selectedPrograms];

        const entries = clonePrograms
                .map(i => this.getSericeEntryId(i) + '-entry')
                .map(i => {
                  return steps.find(p => p.id === i);
                })
                .filter(i => i);

        if (entries.length) {
          const dest = this.startSericeQuestions();
          return dest;
        } else {
          const t = this.updateCalculatingStep();
          return 'we-will-now-caculate';
        }        
      } else {
        const t = this.updateCalculatingStep();
        return 'we-will-now-caculate';
      }

      
    }},
    { id: 'sqft-slider', 
      component: (<SqFtSlider image={() => { return `${this.apiURL}/property/aerial?lat=${this.getLat()}&lng=${this.getLng()}`; }} totalLotSize={() => {return this.state.totalLotSize; }} adjustedLotSize={() => { return this.state.adjustedLotSize; }} />),
      waitAction: true,
      trigger: ({value}) => {
        this.adjustedLotSize = value.replace(/sq\s*ft/ig, "").replace(/,/g, "");
        this.adjustedLotSize = parseInt(this.adjustedLotSize);
        return 'intro-program';
      }
}
]
}

updateMeasurImgStep() {
  return new Promise((res, rej) => {
    const step =  {id: 'display-measurit-image' , component: (<HtmlMessageComponent />),
    inputAttributes: {html: `<img src=${this.state.measuritImg} style="width: 100%" />`}, 
    trigger: 'you-colored-lawn-size'};
  
    this._bot.current.addStepToStack(step);
    setTimeout(() => {
      res();
    }, 300);
  })

}

updateCalculatingStep() {
  
  const step =  {
    id: 'get-started',
    component: (<CalculatingQuoteComponent setPriceBlocks={this.setPriceBlocks} apiURL={this.apiURL} selectedServices={this.state.selectedServices} answers={this.answers} 
          ga={this.ga} companyId={this.companyId} botId={this.botId} totalLotSize={this.state.totalLotSize} adjustedLotSize={this.state.adjustedLotSize} chatId={this.state.chatId} customerZip={this.state.customerZip +''} loadingGif={this.botQuoteAnalyzingGif}
           bot={this._bot.current}/>),
    asMessage: false,
    waitAction: true,
    dispatchMessage: () => {
      
    },
    trigger: (val) => {
      const any = [...this.state.priceBlocks, ...this.state.availableServices];
      if(any.length) {
        this._modal.current.handleOpen();
        return 'now-opening-quote';
      } else {
        return 'sqft-too-big';
      }
    }
  }
  this._bot.current.addStepToStack(step);

  return 'get-started';
}

setPriceBlocks(blocks) {

  const priceBlocks = blocks.filter(i => !i.isUpsell);
  const services = blocks.filter(i => i.isUpsell);
  this.setState({loadedPriceBlocks: true, priceBlocks: priceBlocks, availableServices: services});
  this.calculateTotalCosts();
}


lookupZipCode(zip) {
  if (!zip) return;
  fetch.fetchUrl(this.apiURL + '/property/lookup/zip?zip=' + zip, {}, (error, meta, body) => {
    const statusCode = meta.status;
    if (statusCode === 404) {
      console.log("no user found");
    } else {
      const str_body = body.toString();
      let jsonBody = {};
      try {
        jsonBody = JSON.parse(str_body);
        
        const payload = {
          customerCity : jsonBody.city,
          customerState : jsonBody.state
        }
        this.setState(payload);

      } catch(e) {
        console.log('error tracking', e);
      }

    }
  });
}

toDataURL = (url, callback) => {
  return new Promise((res, rej) => {
    var xhr = new XMLHttpRequest();
  xhr.onload = function() {
    if(xhr.status === 404) {
      res(null);
    } else {
      var reader = new FileReader();
      reader.onloadend = function() {
        res(reader.result);
      }
      
      reader.readAsDataURL(xhr.response);
    }

  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
  });
  
}


async setImageState(params) {
  const {customerLat, customerLng} = params;
  const self = this;
  setTimeout(() => {
    self.recordAddressLocation({location: {lat: customerLat, lon: customerLng}  });
  }, 3000)
  
  let aerialImg = await this.toDataURL(`${this.apiURL}/property/aerial?lat=${params.customerLat}&lng=${params.customerLng}`);
  let streetImg = await this.toDataURL(`${this.apiURL}/property/street?city=${params.customerCity}&state=${params.customerState}&zip=${params.customerZip}&address=${params.customerAddress}`);
  

  
  this.setState({streetImg, aerialImg});
}

async lookupEstimateFromSources(params = {}) {
  

  let estimate = -1;
  try {
    let getEstimate = await this.lookupEstimate(params, this.sqftEstimateSource);
    // console.log(getEstimate);
    if (getEstimate.adjustedLotSize === 0 && this.sqftEstimateSource !== 'zillow' && this.sqftEstimateSource !== 'measur-it' && this.zillowFallback) {
        getEstimate = await this.lookupEstimate(params, 'zillow');
    } 


    this.rgCustomerId = getEstimate.rg_cust_id || null;

    if (getEstimate.forwardPortal) {
      this.redirectToPortal = getEstimate.forwardPortal;
    }
    
    if (getEstimate) {
      this.setState(getEstimate, () => {
        const imagePayload = Object.assign({}, this.state, params);
        this.setImageState(imagePayload);
      });
    }
  
  } catch(e) {
    console.log('estimate source lookup', e);
  }

}



lookupEstimate(params, source) {

  const { chatId } = this.state;

  let crmId = '';
  let customerInfo = `&customerName=${this.customerName}&phonenumber=${this.phonenumber}&customerEmail=${this.customerEmail}`


  return new Promise( async (res, rej) => {
    const estimateUrl = `${this.apiURL}/property/estimate/${source}?companyId=${this.companyId}${crmId}${customerInfo}&botId=${this.botId}&chatId=${chatId}&city=${params.customerCity || this.state.customerCity}&state=${params.customerState || this.state.customerState}&zip=${params.customerZip || this.state.customerZip}&address=${params.customerAddress || this.state.customerAddress}`
    fetch.fetchUrl(estimateUrl, {}, (error, meta, body) => {
      const statusCode = meta.status;
      if (statusCode === 404) {
        res(null);
      } else {
        const str_body = body.toString();
        let jsonBody = {};
  
        try {
          jsonBody = JSON.parse(str_body);

          let payload = {
            customerLat : 0,
            customerLng : 0,
            totalLotSize : 0,
            adjustedLotSize : 0
          }


          if (this.sqftEstimateSource === 'zillow') {
            jsonBody = { estimate: {...jsonBody}};
            jsonBody.estimate.lotSizeSqFt = jsonBody.estimate.area_sq_ft;
            payload.totalLotSize = jsonBody.estimate.area_sq_ft;
          }

          let adjusted = Math.round(jsonBody.estimate.lotSizeSqFt * this.sqFtPercentage);
          payload.adjustedLotSize = adjusted;

          if (!jsonBody.estimate.zpid && this.sqftEstimateSource !== 'zillow') {
            adjusted = jsonBody.estimate.lotSizeSqFt
          }

          if (jsonBody.estimate.lat) {
            payload.customerLat = jsonBody.estimate.lat;
            payload.customerLng = jsonBody.estimate.lng;
          }

          if (jsonBody.estimate.address) {
            payload = {
              customerLat : jsonBody.estimate.address[0].latitude[0],
              customerLng : jsonBody.estimate.address[0].longitude[0],
              totalLotSize : jsonBody.estimate.lotSizeSqFt,
              adjustedLotSize : adjusted
            }
          }
          res(payload);
        } catch(e) {
          console.log('error tracking', e);
          let payload = {
            customerLat : 0,
            customerLng : 0,
            totalLotSize : 0,
            adjustedLotSize :0,
          }
          
          res(payload);
        }
  
      }
    });   
  });
}

setCustomerRecord(customerRecord) {

      return new Promise((res, rej) => {
        if(customerRecord.status === -1) res()
        const record = customerRecord.record
        const address = customerRecord.estimate.address[0];
        const payload = {
          customerLat : address.latitude[0],
          customerAddress : address.street[0],
          customerCity : address.city[0],
          customerState : address.state[0],
          customerZip : address.zipcode[0],
          customerLng : address.longitude[0],
          customerName: record.customerName,
          customerEmail: record.customerEmail || 'unknown@lawnbot.biz',
          adjustedLotSize : Math.round(customerRecord.estimate.lotSizeSqFt * this.sqFtPercentage)
        }
        // this.recordChatRecord(payload);
        this.setImageState(payload);

        this.setState(payload);
        res();
      });
       
        
}

getLat() {
  return this.state.customerLat;
}

getLng() {
  return this.state.customerLng;
}


onMessage(event) {
  // Check sender origin to be trusted
  if (event.origin !== "https://draw.measur.it" && event.origin !== "https://rgpp.net"  && event.origin !== "http://127.0.0.1:3001") return;
  var data = event.data;
 if (data.type === 'estimate') {
   this.adjustedLotSize = Math.round(data.value);
   this.recordEstimate(data.value, 'sqft', data.meta.Location);
  //  this.recordChatRecord({measurItImage: data.meta.Location});
   this.setState({estimateModalOpened: false, adjustedLotSize: this.adjustedLotSize, 
          measuritImg: data.image}, async() => {
            await this.updateMeasurImgStep();
            this._bot.current.moveToStep('display-measurit-image');
          });
   
 } else if (data.type === 'unlock') {
   
 } else if (data.response && data.response.Model) {
   //rgpp
   const model = data.response.Model;
   const payload = {
     transactionId: model.AutopayProfileNumber,
     maskedCard: model.LastFour
   }
   this.submitPayment('real-green-payment-processing', payload);

 }
}

recordEstimate(value, units, image) {

  const { chatId } = this.state;

  const dpayload = {
    companyId: this.companyId,
    quoteId: chatId,
    estimate: {
      estimate: value,
      units: units, //'sqft',
      measurItImage: image
    }
  }


  const requestPayload = {
    method: "POST", 
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
    payload: JSON.stringify(dpayload)
  }

  const url = `${this.dashboardApi}/v1/quote/estimate`;

  fetch.fetchUrl(url, requestPayload, (error, meta, body) => {
    if (error) {
      console.log(error);
    } else {
      // if(!chatId) {
        // const record = JSON.parse(body.toString('utf-8'));
        // console.log(record);
      // }
    }
  })
}

parseAddress(addressString) {
  return new Promise((res, rej) => {
    na_parser(addressString, (err, address) => {
      if (err) {
        res({});
      } else {
        res(address)
      }
    });
  })
}

async componentDidMount() {
  const self = this;

  if (window.addEventListener) {
    window.addEventListener("message", this.onMessage, false);        
  } 
  else if (window.attachEvent) {
      window.attachEvent("onmessage", this.onMessage, false);
  }

  //set up GA
  setTimeout(() => {
    const tracker = window.ga.getAll()[0];
    window.ga('require', 'ecommerce');
    self.ga = tracker;
  }, 2000);

  this.lookupCompany().then( async () => {
    this.setupAvailableServices();
    this.lookupCustomer();

    // const record = await this.recordChatRecord({});

    const urlParams = new URLSearchParams(window.location.search);
    const params = {};
    for(var p of urlParams.entries()) {
      params[p[0]] = p[1]; 
    }

    if (params.source) {
      if (params.source === 'contactForm') {
        params['noPII'] = false;
      }
    }

    let startChat = false;
    if(params.googlelocate) {
      startChat = true;
      const stripped = params.googlelocate.replace(/,\s*USA,\s*/, " ").replace(/%/g, "");
      const isUK = stripped.indexOf(" UK") > -1 ? true : false;

     
      // const ap = parser.parseLocation(stripped);
      const na = await self.parseAddress(stripped);
 
      if (!na.postal_code && isUK) {
        const UKReg = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/ig;
        let matches = stripped.match(UKReg);
        matches = matches.filter(i => i);
        if (matches.length) {
          na.postal_code = matches[0];
        }

      }


      params.customerAddress = `${na.street_address1}`.trim();
      params.customerCity = na.city;
      params.customerState = na.state;
      params.customerZip = na.postal_code;

      delete(params.googlelocate);
      
    } else if (params.source && params.source === 'contactForm') {
      // http://127.0.0.1:8080/?source=contactForm&phonenumber=Shells&customerName=Michelle%20Lysykanycz&customerEmail=lilypondpons%40yahoo.com&customerAddress=294%20Lenape%20Trail&customerZip=18104
      startChat = true;
    } else {
      
      if (params.customerEmail && params.customerName && params.customerAddress && params.customerZip && params.phonenumber) {
        params['noPII'] = false;
      }
      console.log(params);
      startChat = true;
    }

    if (params.customerState && params.customerState.length && params.customerState.length > 3) {
      params.customerState = translateState(params.customerState);
    }
   

    if (params.phonenumber) {
      params.phonenumber = params.phonenumber.replace(/\s*/g, "").replace(/[(|)]*/gi, "").replace(/-/g, '');
    }

    this.setState(params);

    if (startChat) {
      setTimeout(async () => {
        const quote = await self.startChatRecord();
        self.setState({chatId: quote});
      }, 400)
      
    }


    if (params.customerZip && this.lookupZipCodeTax) {
      this.lookupPostalTaxRate(params.customerZip);
    }

    if (params.source) {
      if (params.source === 'contactForm') {
        this.lookupEstimateFromSources(params);
      }
    }
    this.calculateTotalCosts();
  });
}

getPaymentToken = (id) => {

  const requestPayload = {
    method: "POST", 
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
    payload: JSON.stringify({companyId: this.companyId, botId: this.botId, amount: 1.0, crmId: id})
  }

  const url = `${this.dashboardApi}/v1/quote/crm/realgreen/payment`;

  return new Promise((res, rej) => {
    fetch.fetchUrl(url, requestPayload, (error, meta, body) => {
      if (error) {
        console.log(error);
        res(null)
      } else {
        // if(!chatId) {
          const record = JSON.parse(body.toString('utf-8'));
          this.setState({paymentToken: record.token})
          // console.log("got payment token", record.token);
          res(record.id)
        // }
      }
    })
  })
}

startChatRecord = ( ) => {
  
  let dpayload = Object.assign({}, this.buildTrackingPayload(), {});
  dpayload = _.pickBy(dpayload, _.identity);
  const requestPayload = {
      method: "POST", 
      headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
      },
      payload: JSON.stringify({companyId: this.companyId, botId: this.botId, customer: dpayload})
  }

  const url = `${this.dashboardApi}/v1/quote/start`;

  return new Promise((res, rej) => {
    fetch.fetchUrl(url, requestPayload, (error, meta, body) => {
      if (error) {
        console.log(error);
        res(null)
      } else {
        // if(!chatId) {
          const record = JSON.parse(body.toString('utf-8'));
          this.setState({chatId: record.id, crmId: record.crmId})

          if (record.status && (record.status === "8" || record.status === "9")) {
            console.log("were are here", record.status)
            if (record.redirectUrl) {
              this.redirectToPortal = true;
              this.crmPortalUrl = record.redirectUrl;
              this._bot.current.moveToStep('message-redirect-to-portal');
            }
          } else {
            if (record.crmId) {
              this.getPaymentToken(record.crmId);
            }
            res(record.id)
          }
        // }
      }
    })
  })

};
  

recordAddressLocation = async ( data = {}) => {
  const { chatId } = this.state;
  if ( !chatId) {
    console.log("in the loop");
    const self = this;
    setTimeout(() => {
      self.recordAddressLocation(data);
    }, 400);
    return;
  }
  
  let dpayload = Object.assign({}, {companyId: this.companyId, quoteId: chatId}, data);
  dpayload = _.pickBy(dpayload, _.identity);
  const requestPayload = {
    method: "POST", 
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
    payload: JSON.stringify(dpayload)
  }
  const url = `${this.dashboardApi}/v1/quote/location`;

  return new Promise((res, rej) => {
    fetch.fetchUrl(url, requestPayload, (error, meta, body) => {
      if (error) {
        console.log(error);
        res(null)
      } else {
        // if(!chatId) {
        //   const record = JSON.parse(body.toString('utf-8'));
        //   this.setState({chatId: record.id}, () => {
        //     res(null);
        //   });
        // }
      }
    })
  })
  
}

recordChatRecord = async ( data = {}) => {
    const { chatId } = this.state;
    if (typeof chatId === 'undefined') return;

    let dpayload = Object.assign({}, data);
    dpayload = _.pickBy(dpayload, _.identity);
  
    const requestPayload = {
      method: "POST", 
      headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
      },
      payload: JSON.stringify({quoteId: chatId, payload: dpayload, companyId: this.companyId})
    }
    const url = `${this.dashboardApi}/v1/quote/record`;

    return new Promise((res, rej) => {
      fetch.fetchUrl(url, requestPayload, (error, meta, body) => {
        if (error) {
          console.log(error);
          res(null)
        } else {
          if(!chatId) {
            const record = JSON.parse(body.toString('utf-8'));
            this.setState({chatId: record.id}, () => {
              res(null);
            });
          }
        }
      })
    })
    
}

lookupPostalTaxRate(postalCode) {

  const taxString = postalCode + "";
  if (this.dontTaxZipCodes.indexOf(taxString) !== -1) {
    this.serviceTax = 0.0;
    return;
  }

  const requestPayload = {
    method: "GET", 
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    }
  }
  let payloadUrl = this.apiURL+`/tax?postalCode=${postalCode}`;

  fetch.fetchUrl(payloadUrl, requestPayload, (error, meta, body) => {
      if(error) {
        console.log(error);
      } else {
        const response = JSON.parse(body.toString('utf-8'));
        if(response.tax === -1) {
          console.log("no tax rate found for this zip");
        } else {
          this.serviceTax = response.tax * 100;
        }
      }
  });
}

buildTrackingPayload() {
  const {
            customerLat, 
            customerLng ,
            customerAddress ,
            customerCity ,
            customerState ,
            customerZip ,
            phonenumber ,
            customerName,
            customerEmail
  } = this.state;

  return  {
        name: customerName,
        lat: customerLat,
        lng: customerLng,
        phonenumber: phonenumber,
        customerAddress,
        customerCity,
        customerState,
        customerZip,
        email: customerEmail
      }

}

loadSteps() {

  let compiledSteps = [];

  [ 
    // ...generateIrrigationServiceQuestions(this),
    // ...generateLawnServiceSteps(this), 
    ...this.probingQuestionsSteps,
    
    // ...generatePestServiceSteps(this),
    ...generateObjectionSteps(this),
    ...this.serviceQuestionsSteps,
    ...this.learnMoreSteps,
    ...this.preModalQuestions,
    ...this.objectionSteps].forEach(i => {
        let _all = [];

        const definedSteps = (i.steps || []).map(q => {
          if (!q.message) return q;
          if ((q.message instanceof String || typeof q.message === 'string') && q.message.indexOf("#name#") > 0) {
            const self = this;
            const msg = q.message;
            const fnc = ({previousValue, steps}) => {
              let strmessage = msg
                                .replace("#name#", self.state.customerName.trim().split(" ")[0]);
              return strmessage;
            };
            q.message = fnc;

            return q;
          } else {
            return q;
          }
        });

        const gsteps = generateStepTrigger(i, this).map(t => {
          if (t.options) {
            delete(t.trigger);
          }
          delete(t.conditionals);
          return t;
        });

        _all = [..._all, ...definedSteps ,...gsteps];
      
        compiledSteps = [...compiledSteps, ..._all];
      });
      const {steps} = this.state;

      this.setState({ steps: [...steps, ...compiledSteps], loading: false});
}

updatePriceBlocks(id, value, type = 'program') {

  const { priceBlocks, availableServices } = this.state;
  
  if (type === 'program') {
      const newBlocks =  priceBlocks.map(i => {
        if (i.id === id) {
          i.selected = value;
        }
        return i;
      });
      this.setState({priceBlocks: newBlocks});
  } else if (type === 'service') {
    const newBlocks =  availableServices.map(i => {
      if (i.id === id) {
        i.selected = value;
      }
      return i;
    });
    this.setState({ availableServices : newBlocks});
  }
  this.calculateTotalCosts();
}

  updatePricingTerms(term) {
    this.setState({term: term});
    this.calculateTotalCosts();
  }

  costLoop(target = [], term, all) {
    let price = 0;
    const tt = target.filter(i => i.selected);
    if (!tt.length) return price;
    tt.forEach((i) => {
      let newprice = 0;
      if (term === 'application') {
        let obj = i.pricing.filter(ii => ii.frequencyType === 'application');
        if (obj.length) {
          obj = obj[0];
          if (obj.pricingMethod === 'monthly-payment') {
            newprice = all ? obj.totalApplicationCost : obj.servicePrice;
          } else {
            newprice = all ? obj.servicePrice * obj.remainingPeriods : obj.servicePrice;
          }
          
        }
      } else {
        let obj = i.pricing.filter(ii => ii.frequencyType === 'total');
        if (obj.length) {
          obj = obj[0];
          newprice = obj.servicePrice;
        }
      }
      
      price += newprice;
    });
    return price;
  }

  calculateTotalCosts() {
  
    const { availableServices, priceBlocks} = this.state;

    const regularServiceCost = this.costLoop(availableServices, 'application', true);
    const regularProgramCost = this.costLoop(priceBlocks, 'application', true)

    const totalServiceCost = this.costLoop(availableServices, 'total', true);
    const totalProgramCost = this.costLoop(priceBlocks, 'total', true);
    const firstServiceCost = this.costLoop(availableServices, 'application', false);
    const firstProgramCost = this.costLoop(priceBlocks, 'application', false);

    const allTotal = totalProgramCost + totalServiceCost;
    const firstTotal = firstProgramCost + firstServiceCost;
    const regularTotal = regularProgramCost + regularServiceCost;
   
  let discount = 0.0;
  
  discount = (regularTotal - allTotal);

  discount = discount < 0 ? 0.0 : discount;

  if (!regularTotal) {
    discount = 0.0;
  }
   this.setState({allTotal, firstTotal, regularTotal, discount});
   
  }

  sendLeadInfo = () => {
    const { chatId } = this.state;
    const chatPayload= {
      chatId
    }
    if (this.crmSolution === 'real-green') {
      chatPayload['rg_cust_id'] = this.rgCustomerId;
    }
   
    const requestPayload = {
      method: "POST", 
      headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
      },
      payload: JSON.stringify(chatPayload)
    }
    let payloadUrl = this.apiURL+`/company/${this.companyId}/${this.botId}/thinkaboutit`;

    fetch.fetchUrl(payloadUrl, requestPayload, (error, meta, body) => {
        if(error) {
          console.log(error);
        }
    });
  }

  submitPayment = (type, payload) => {
 
    const { term, firstTotal, allTotal, customerEmail, phonenumber, priceBlocks, chatId, charging, availableServices } = this.state;
    if (charging) return;
    this.setState({ charging: true });
    // const price = term === 'total' ? allTotal : firstTotal;
    // const taxAmount = price * (this.serviceTax / 100) ;
    // const amount = price + taxAmount;
    // const amount = term === 'application' ? firstTotal : allTotal;

    let expiry = "1 / 2029"

    switch(type) {
      case 'lawnbot-credit-card-vault':
        expiry = payload.expiry;
        break;
      case 'real-green-payment-processing':
        break;
      default:
        expiry = payload.exp;
    }
  
    expiry = expiry.split(/\s*\/\s*/);

    const dashboardPayload = {"companyId": this.companyId,
                              "quoteId": chatId,
                              "expire_month" : expiry[0],
                              "expire_year" : expiry[1],
                              "programs": [ ]
                            }
    if (payload.expiry) {}
    
    /// build dashboard program payload

    const sp = priceBlocks.filter(i => i.selected)
    .map(i => {
      let pricing = i.pricing.find(p => p.frequencyType === term);
      const total = i.pricing.find(p => p.frequencyType === 'total').servicePrice;

      let appPricing = i.pricing.find(p => p.frequencyType === 'application');

      let regAppPricing = 0;
      if (appPricing) {
        regAppPricing =  typeof appPricing.regularApplicationCost !== 'undefined' ? appPricing.regularApplicationCost:  appPricing.servicePrice;
        const regAppPrice = typeof pricing.regularApplicationCost !== 'undefined' ? pricing.regularApplicationCost:  pricing.servicePrice;
      }
      

      return {
        programName: i.serviceName,
        applications: i.applicationsRemaining,
        prePayPrice: total,
        totalProgramCost: (i.applicationsRemaining * regAppPricing).toFixed(2),
        firstAppPrice: typeof pricing.regularApplicationCost !== 'undefined' ? pricing.servicePrice : pricing.regularApplicationCost,
        applicationPrice: regAppPricing,
        term,
        salesTax: this.serviceTax,
        prePayDiscount: i.prePayDiscount,
        serviceType: i.serviceType,
        pricingMethod: pricing.pricingMethod,
        pricingPeriods: pricing.pricingPeriods
      }
    });


    const ss = availableServices.filter(i => i.selected)
                .map(i => {
                  const total = i.pricing.find(p => p.frequencyType === 'total').servicePrice;
                  let pricing = i.pricing.find(p => p.frequencyType === term);

                  let appPricing = i.pricing.find(p => p.frequencyType === 'application');
                  const regAppPricing =  typeof appPricing.regularApplicationCost !== 'undefined' ? appPricing.regularApplicationCost:  appPricing.servicePrice;
                  const regAppPrice = typeof pricing.regularApplicationCost !== 'undefined' ? pricing.regularApplicationCost:  pricing.servicePrice;


                 return {
                    programName: i.serviceName,
                    applications: i.applicationsRemaining,
                    prePayPrice: total,
                    totalProgramCost: (i.applicationsRemaining * regAppPricing).toFixed(2),
                    firstAppPrice: pricing.regularApplicationCost !== 'undefined' ? pricing.servicePrice : pricing.regularApplicationCost,
                    applicationPrice: typeof pricing.regularApplicationCost !== 'undefined' ? pricing.regularApplicationCost:  pricing.servicePrice,
                    term,
                    salesTax: this.serviceTax,
                    prePayDiscount: i.prePayDiscount
                  }
              });
    
    const tq = [...sp, ...ss];

    dashboardPayload.programs = tq;

   

    // const paymentPayload = {email: customerEmail, 
    //                         chatId,
    //                         phone: phonenumber, amount,  
    //                         selectedPrograms: tq,
    //                         term,
    //                         totalProgramCosts,
    //                         ...payload};


    let paymentUrl = this.apiURL+`/company/${this.companyId}/${this.botId}/payment/card`;

    if (type === 'token') {
      paymentUrl = this.apiURL+`/payment/device`;
      dashboardPayload.paymentMethod = 'apple-pay'
      dashboardPayload.paymentInfo = payload;
    } else if (type === 'lawnbot-credit-card-vault' ){
      paymentUrl = this.dashboardApi + '/v1/secure/payment'
      dashboardPayload.paymentMethod = 'lawnbot-credit-card-vault';
      dashboardPayload.paymentInfo = { token: payload};
    } else if (type === 'real-green-payment-processing' ){
      paymentUrl = this.dashboardApi + '/v1/secure/payment'
      dashboardPayload.paymentMethod = type;
      dashboardPayload.paymentInfo = { ...payload};
    } else {
      /// it's stripe
      dashboardPayload.paymentMethod = 'stripe';
      dashboardPayload.paymentInfo = payload;
      paymentUrl = this.dashboardApi + '/v1/secure/payment';
      dashboardPayload.botId = this.botId;
    }


    dashboardPayload.term = term;

    const requestPayload = {
      method: "POST", 
      headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
      },
      payload: JSON.stringify(dashboardPayload)
    }

    fetch.fetchUrl(paymentUrl, requestPayload, (error, meta, body) => {
      if (error || !body) {
        console.log("we got a error from api - stripe");
        this.ga.send( 'event', 'purchase', 'error', 1);
        this.setState({ charging: false });
      } else {
        try {
          const response = JSON.parse(body.toString('utf-8'));
          if (response.error) {
            alert(response.error);
            this.setState({ charging: false });
          } else {
            this.ga.send( 'event', 'purchase', 'success', 1);
           
            // this.ga.send('ecommerce:addTransaction', {
            //   'id': this.companyId,                     // Transaction ID. Required.
            //   'affiliation': this.botId,   // Affiliation or store name.
            //   'revenue': `${amount}`,               // Grand Total.
            //   'shipping': '0',                  // Shipping.
            //   'tax': `${taxAmount}`                     // Tax.
            // });
            this.setState({ charging: false });
            this._modal.current.handleClose();
            this._bot.current.moveToStep('congrats-sale');
            
          }
        } catch(e) {
          console.log(e);
          console.log('we got a parser error');
          this.setState({ charging: false });
        }

        
      }
      
    });
    
  }

  thinkAboutIt() {
          this.ga.send( 'event', 'objection', 'thinkaboutit-modal', 1);
          
          this._modal.current.handleClose();
          this._bot.current.moveToStep('objection-entry');
  }




  render() {
    const {steps, priceBlocks, term, availableServices, allTotal, 
          firstTotal, regularTotal, discount, loading, companyLoading, 
          customerAddress, customerName, streetImg, aerialImg, crmId, paymentToken} = this.state;

    return (<div>
      { !loading && !companyLoading ? (
      <StripeProvider apiKey="pk_test_csir1qK3dl1j1Dm4OE7NJTEt">
    <div>
  
      <ChatContainer>
      <ChatBot steps={steps}
      className="chatbot"
      ref={this._bot}

      headerComponent={(<ServiceBotHeader logo={this.companyLogo} tagLine={this.companyTagLine}/>)}
      botDelay={this.botSpeed}
      botAvatar={this.companyAvatar}
      width={'100%'}
      height={'100%'}
      style={{...this.mobileStyle, boxShadow: 'none',  paddingRight: 15, boxSizing: 'content-box'}}
      enableSmoothScroll={true}
      footerStyle={{display: 'none'}}
      hideUserAvatar={true}
      bubbleStyle={this.bubbleStyle}
      handleEnd={(event) => {
     
        if (this.willRedirect && this.purchaseMade) {
          setTimeout(() => {
            window.top.location.href = this.redirectUrl;
          }, 2000)
         
        }
      }}
      />
      </ChatContainer>
     
      <SimpleModal ref={this._modal} url={streetImg || aerialImg } priceBlocks={priceBlocks} term={term} cardProcessor={this.creditCardPaymentType}
      updateSelection={this.updatePriceBlocks} updatePricingTerms={this.updatePricingTerms} availableServices={availableServices} allTotal={allTotal} firstTotal={firstTotal}
      regularTotal={regularTotal} discount={discount} submitPayment={this.submitPayment} customerRecord={{address: customerAddress, name: customerName}}
      sqft={this.state.adjustedLotSize} thinkAboutIt={this.thinkAboutIt} companyTeamPic={this.quoteModalImage} logo={this.companyLogo}
      serviceTax={this.serviceTax} autoPayAvailable={this.autoPayAvailable} prePayAvailable={this.prePayAvailable} hideSummary={this.hideSummary} autoPayAlternativeLabel={this.autoPayAlternativeLabel} bypassPayment={this.bypassPayment}
      autoPayPriceMethod={this.autoPayPriceMethod} autoPayMonthlyPricePeriod={this.autoPayMonthlyPricePeriod} charging={this.state.charging}
      isLive={this.isLive} tos={this.termsOfService} crmId={crmId} paymentToken={paymentToken} isMetric={this.isMetric} measurementMode={this.measurementMode} company={this.companyId}
      />

      <MeasureItModal company={this.companyId} estimate={this.getEstimate} modalOpened={this.state.estimateModalOpened} address={`${this.state.customerAddress}, ${this.state.customerCity} ${this.state.customerState} ${this.state.customerZip}`} isMetric={this.isMetric} measurementMode={this.measurementMode} />

  </div>
  </StripeProvider>) : (<div>
    <Desktop>
    <LoadingScreen
    bgColor='#ffffff'
    spinnerColor='#9ee5f8'
    textColor='#676767'
    logoSrc={this.companyLogo}
    text={this.companyTagLine}
  /> 
    </Desktop>
    <Mobile>
      <LoadingScreen

      bgColor='#ffffff'
      spinnerColor='#9ee5f8'
      textColor='#676767'
      logoSrc={this.companyLogo}
      text=''
    /> 
    </Mobile>
    <Tablet>
    <LoadingScreen
    bgColor='#ffffff'
    spinnerColor='#9ee5f8'
    textColor='#676767'
    logoSrc={this.companyLogo}
    text={this.companyTagLine}
  /> 
    </Tablet>
    </div>) }</div>
  );
}

}

export default App;
