import React, { createRef } from 'react';
import Cards from 'react-credit-cards';
import StripeCheckoutForm from '../components/payment/stripe.checkout-form';
import PropTypes from 'prop-types';
import { Desktop } from '../../../utils/media-queries';
import CreditCardInput from 'react-credit-card-input';

import SecureFields from './../components/payment/pci-proxy';
import { CircularProgress } from '@material-ui/core';

export default class PaymentForm extends React.Component {

  state = {
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
    cardValid: false,
    cardReady: false,
  };
  constructor(props) {
    super(props);
    this.handleBlur = this.handleBlur.bind(this);
    this.checkValid = this.checkValid.bind(this);

    this.paymentProcessor = 'cardVault';
    this.secureForm = createRef();

    if (this.props.registerCharge) {
      this.props.registerCharge(this.chargePciProxy);
    }
   
  }

  componentDidMount() {
    this.checkValid();
  }

  handleInputFocus = (e) => {
    console.log(e.target.id)
    this.setState({ focus: e.target.id.replace("card-", "") });
  }

  handlePCIProxyInputFocus = (e) => {

    let val = 'number';
    if (e.field === "cardNumber") {
      val = 'number';
    } else {
      val = 'cvv';
    }
    this.setState({ focus:  val});
    return false;
  }
  
  handlePCIProxyInputChange = (e) => {
    const {saveCardInfo, togglePayButton} = this.props
    let valid = true;
    for (let i in e) {
      if (!e[i].valid) valid = false;
    }

   
    const btnState = valid ? 'enable' : 'disabled';
        togglePayButton(btnState);
        this.setState({cardReady: valid});
    
  }

  handleInputChange = (e) => {
    let { id, value } = e.target;

    let name = id.replace('card-', "").replace(/\s*/g, "").replace(/\//g, "");
    
    this.setState({ [name]: value });

    this.checkValid();
  }

  handleBlur = (e) => {
    this.setState({focus: 'number'})
    this.checkValid();

  }

  chargePciProxy = (e) => {
    this.secureForm.current.secureFields.submit();
  }

  

  submitCard = (e) => {
    if ((this.state.cardReady && this.props.allTotal > 0 && !this.props.bypassPayment) ||
        (this.props.allTotal > 0 && this.props.bypassPayment) ) {
          if (!this.props.charging) {
            const { number, expiry, cvc } = this.state;
            const { submitPayment } = this.props;
            const stripped = number.replace(/\s/g, "");
            const payload = {number: stripped, cvc, exp: expiry};
      
            submitPayment('card', payload);
          } else {
            console.log("don't charge");
          }
     
    } else {
      /// don't charge
    }
  }

  submitTransaction = (data) => {
    const {transactionId, expiry} = data;
    const { submitPayment, saveCardInfo } = this.props;
   

    // console.log("got the transactionId", transactionId);
    if ((this.props.allTotal > 0 && !this.props.bypassPayment) ||
        (this.props.allTotal > 0 && this.props.bypassPayment) ) {
          if (!this.props.charging) {
            submitPayment('lawnbot-credit-card-vault', data);
          } else {
            console.log("no charging");
          }
      
    } else {
      console.log("no money")
    }
  }
  
  isValid = (type, isValid) => {
    this.setState({cardValid: isValid});
    this.checkValid();
  }

  checkValid = () => {
    const {number, cvc, expiry} = this.state;
    const {saveCardInfo, togglePayButton} = this.props
    const stripped = number.replace(/\s/g, "");

    let exp = expiry;
    if ( exp.length >= 3) {
      exp = exp.replace(/^([0-9]{2})\s*\/*\s*([0-9]*)$/g, '$1 / $2');
    }

    saveCardInfo({number: stripped, cvc, exp});

    const amount = (this.props.term === 'application') ? this.props.firstTotal : this.props.allTotal;
    if(this.state.cardValid && this.state.cvc.length >= 3 && this.state.expiry.length >= 4) {
      /// enable button
      if (amount > 0) {
        togglePayButton('enable');
        this.setState({cardReady: true});
        return true;
      } else {
        togglePayButton('disabled');
        this.setState({cardReady: false});
        return true;
      }
    } else {
      /// disable button
      togglePayButton('disabled');
      this.setState({cardReady: false});
      return false;
    }
  }

  getPricing = () => {
    return this.props.term === 'application' ? (this.props.firstTotal + this.props.firstTotal * (this.props.serviceTax / 100)).toFixed(2) : (this.props.allTotal + this.props.allTotal * (this.props.serviceTax / 100 )).toFixed(2);
  }

  render() {

    const currency = (this.props.company === '6254dc1a-3be7-4c26-bc0e-c475b3012721') ? '€' : '$';
   

    const { number, expiry, cvc, focus, name } = this.state;
    return !this.props.bypassPayment ? (
      <div id="PaymentForm" onBlur={this.handleBlur} style={{minWidth: 300, textAlign: 'center'}}>
       { this.props.cardProcessor === 'stripe' ? (<Cards
          cvc={cvc}
          expiry={expiry.replace("/", "")}
          focused={focus}
          name={name}
          number={number}
          issuer={'visa'}
          callback={this.isValid}
        />) : (<img src={'/assets/images/creditcard.png'} style={{width: '95%'}}/>) }
        
      <div style={{display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'center',
                  justifyContent: 'space-around',
                  fontFamily: 'Open Sans',
                  fontSize: 17,
                  maxWidth: '80%',
                  margin: 'auto',
                  flexWrap: 'wrap'}}>

{this.props.cardProcessor === 'stripe' ? ( <CreditCardInput
  cardNumberInputProps={{ value: this.state.number, onChange: this.handleInputChange, onFocus: this.handleInputFocus }}
  cardExpiryInputProps={{ value: this.state.expiry, onChange: this.handleInputChange, onFocus: this.handleInputFocus }}
  cardCVCInputProps={{ value: this.state.cvc, onChange: this.handleInputChange, onFocus: this.handleInputFocus }}
  fieldClassName="input"
  />) : null }

{this.props.cardProcessor === 'lawnbot-credit-card-vault' ? (
  <SecureFields production={this.props.isLive} ref={this.secureForm} config={{
    merchantID: this.props.isLive ? '3000016939':  '1100024951',
    fields:{
      cardNumber: {
        placeholderElementId: 'pci-card-number',
        inputType: 'tel',
      },
      cvv: {
        placeholderElementId: 'pci-cvv-number',
        inputType: 'tel'
      }
    },
    options: {}
  }  }
    focusChange={this.handlePCIProxyInputFocus}
    paymentMade={this.submitTransaction}
    cardInfo={this.handlePCIProxyInputChange}
  />
): null}

{this.props.cardProcessor === 'real-green-payment-processing' && this.props.crmId ? (
  <iframe title={'rgpp'} id="payment-iframe" width="100%" height="670px" seamless="seamless" frameborder="0"
    allowtransparency="true" src={"https://rgpp.net/Process/Process/lawnbotautopay?id=" + this.props.paymentToken}>
  </iframe>
) : null}


</div>  

<div style={{    marginLeft: '10%',
                        marginRight: '10%',
                        fontSize: 13,
                        marginTop: 15,
                        color: 'rgb(169 150 150)',
                        position: 'relative',
                        marginBottom: 20}}>Your card information will be encrypted to securely process payment.</div>
 
  <Desktop>
    {this.props.cardProcessor === 'real-green-payment-processing' && this.props.crmId ? null : (
  <div style={{paddingLeft: 35, paddingRight: 35, width: '79%', marginTop: 20}}>
        <div style={{width: '100%', borderRadius: 25, 
                    backgroundColor: this.state.cardReady && this.props.allTotal > 0 && !this.props.charging ? '#3597EC' : '#ccc', 
                    height: 60,
                    color: 'white',
                    fontSize: 26,
                    fontWeight: 'bolder',
                    paddingTop: 27,
                    textAlign: 'center', cursor: 'pointer'}} onClick={this.props.cardProcessor === 'lawnbot-credit-card-vault' ?  this.chargePciProxy: this.submitCard}>
                      {this.props.charging ? (<CircularProgress />) : null }
                      {this.props.cardProcessor === 'lawnbot-credit-card-vault' && this.props.term === 'application' ? (<span style={{top: this.props.charging ? -13: 0, position: 'relative', marginLeft: 10}}>Sign Up</span>) : null }
                      {this.props.cardProcessor === 'lawnbot-credit-card-vault' && this.props.term !== 'application' ?
                      (<span style={{top: this.props.charging ? -13: 0, position: 'relative', marginLeft: 10}}>Pay {currency}{this.getPricing()}</span>) : 
                      null}
                      {this.props.cardProcessor === 'stripe' ? (<>{currency}{this.getPricing()}</>) : null }
        </div>
        </div>) }
  </Desktop>
       
      
        <StripeCheckoutForm {...this.props} />
      </div>
    ) : (
      <Desktop>
        <div id="PaymentForm" style={{width: 250}}>
        <div style={{paddingLeft: 40, paddingRight: 40, width: '79%', marginTop: 20}}>
        <div style={{width: '100%', borderRadius: 25, 
                    backgroundColor: this.props.allTotal > 0 ? '#3597EC' : '#ccc', 
                    height: 60,
                    color: 'white',
                    fontSize: 26,
                    fontWeight: 'bolder',
                    paddingTop: 27,
                    textAlign: 'center', cursor: 'pointer'}} onClick={this.submitCard}>
                      ⚡ BUY NOW
        </div>
        </div>
        </div>
  </Desktop>
    );
  }
}


PaymentForm.propTypes = {
  submitPayment: PropTypes.func,
  togglePayButton: PropTypes.func,
  saveCardInfo: PropTypes.func
}