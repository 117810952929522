import React, {Component} from 'react';

export class ProgramSelectComponent extends Component {

    containerStyle = { flex: 1, alignContent: 'flex-start', display: 'flex', 
    justifyContent: 'space-between', flexWrap: 'wrap',  minHeight: 240, marginBotton: 50}

    programs = [];
    constructor(props) {
        super(props);
        this.state = {
            selectedPrograms: [],
            availablePrograms: this.props.available_programs,
            showButton: true,
            apiURL: this.props.api || 'http://localhost:3000',
            locked: false
        };
      }

    displayPrograms(self) {
        const  {selectedPrograms, availablePrograms} = this.state;

        return availablePrograms.map(i => {
            const key = 'program'+Math.floor(Math.random()*100000)
            return (<ProgramSelectButton key={key} image={i} selected={selectedPrograms.includes(i)} togglePrograms={(a,b) => this.togglePrograms(a,b)} />)
        })
    }

    togglePrograms(program_name, add_subtract) {
        const {selectedPrograms, locked} = this.state;
        if (locked) return
        let programs = selectedPrograms.slice(0);
        if (add_subtract === 'add') {
            programs.push(program_name);
        } else {
            programs = programs.filter(i => i !== program_name);
        }
        this.setState({selectedPrograms: programs});
    }
    submit() {
        const {triggerNextStep} = this.props;
        const {selectedPrograms} = this.state;
        this.setState({showButton: false, locked: true});
        triggerNextStep({value: selectedPrograms});
    }

    render() {
        const { showButton, selectedPrograms } = this.state;
        return (<div><div style={this.containerStyle}>{this.displayPrograms(this)}
        { selectedPrograms.length ? (<SelectProgramsButtonComponent display={showButton} label={'Next'} callback={()=> this.submit()}/>) : null }
        </div></div>)
    }
  }

  class ProgramSelectButton extends Component {

        imgStyle = {width: '100%', height: '100%',
        borderRadius: 10
        }

        buttonStyle = {
          padding: 3,
          margin: 1,
          width: 120,
          height: 130,
          border: '1px solid rgba(112,112,112, 0.39)',
          borderRadius: 15,
          marginRight: 1,
          marginBottom: 10,
          backgroundColor: 'rgba(53,151,236, 0.15)', 
        }

        uncheckedStyle = {backgroundColor: 'white'};
        checkedStyle = {backgroundColor: 'rgba(53,151,236, 1,0)', borderColor: 'red'};

        currentStyle = this.uncheckedStyle;

        selectedImage;

    constructor(props) {
        super(props);
        const img = new Image();
        const img2 = new Image();
        
        const svgimage = this.getSvgIcon();
        
        img.src = `/assets/icons/services/images/${svgimage}.png`
        img2.src = `/assets/icons/services/images/${svgimage}-selected.png`

        this.state = {
          currentStyle: this.currentStyle
        }
    }


    getSvgIcon() {
      let svgimage = 'fertilizing';
      const { image } = this.props;
        switch(image) {
          case 'Fertilizer & Weed Control':
            svgimage = 'fertnweed';
            break;
          case 'Mosquito Control':
            svgimage = 'mosquito';
            break;
          case "Mowing":
            svgimage = 'mowing';
            break;
          case "Lawn Seeding":
            svgimage = 'lawn-seeding';
            break;
          case "Irrigation Services":
            svgimage = 'irrigation';
            break;
          case "Perimeter Pest Control":
            svgimage = "perimeterpest";
            break;
          case "Pest Control":
            svgimage = "pestcontrol";
            break;
          case "Tick":
              svgimage = "tick";
              break;
          case "Mosquito & Tick":
              svgimage = "mosquito-and-tick";
              break;
          case "Flea & Tick":
            svgimage = "flea-and-tick";
            break;
          case "Flea & Ant":
            svgimage = "flea-and-ant";
            break;
          case "Covid":
              svgimage = "covid";
              break;
          case "Snow Removal":
            svgimage = "snow";
            break;
          case "Aeration":
            svgimage = "aeration";
              break;
          case "Aeration & Overseed":
            svgimage = "aeration-overseed";
            break;
          case "Liquid Aeration":
            svgimage = "liquid-aeration";
            break;
          case "Army Worm":
            svgimage = "armyworm";
            break;
          case "Fire Ant":
            svgimage = "fireant";
            break;
          case "Air 8":
            svgimage = "air8";
            break;
          case "Yard Clean Up":
            svgimage = "yardcleanup";
            break;
          case "Spotted Lantern Fly":
            svgimage = "lantern-fly";
            break;
          case "Thatch":
            svgimage = "thatch";
            break;
          case "Mole Control":
            svgimage = "mole-control";
            break;
          case "Bin Cleaning":
            svgimage = "bin-cleaning";
            break;
          default:
        }

        return svgimage;
    }
   
    
    toggleButton() {
    
        const {togglePrograms, image, selected} = this.props;
        const newSelect = selected ? false : true;
        const currentStyle = newSelect ? this.checkedStyle : this.uncheckedStyle;
        this.setState({currentStyle});
        const action = newSelect ? 'add' : 'remove';
        togglePrograms(image, action);
    }

     render() {
        const { image, selected } = this.props;
        const svgimage = this.getSvgIcon();
        let suffix = '';
        let selecteStyle = this.uncheckedStyle;
        if (selected) {
          suffix = '-selected';
          selecteStyle = this.selecteStyle;
        }

       return (<div style={{...this.buttonStyle, ...selecteStyle}}><img alt={image} src={`/assets/icons/services/images/${svgimage}${suffix}.png`} style={{...this.imgStyle}}
       onClick={() => {this.toggleButton()}} /></div>)
     }
  }



  class SelectProgramsButtonComponent extends Component {
                style = {
                    boxShadow: '0px 0px 0px 2px #9fb4f2',
                    backgroundColor:'rgb(20, 126, 251)',
                    borderRadius:10,
                    border:'1px solid #4e6096',
                    display:'inline-block',
                    cursor:'pointer',
                    color:'#ffffff',
                    fontFamily:'Arial',
                    fontSize:19,
                    padding:'12px 37px',
                    textDecoration:'none',
                    textShadow:'0px 1px 0px #283966',
                    width: '80%',
                    textAlign: 'center',
                    marginBottom: 50}

            clickIt() {
                const {callback} = this.props;
                callback();
            }

            render() {
                const {label, display} = this.props;
                if (!display) return null;
                return (<div style={this.style} onClick={() => this.clickIt()}>{label}</div>);
            }
  }