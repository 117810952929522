import { invalidInput } from '../common/animations';
import { css } from 'styled-components';
import React, { Component } from 'react';
import { Mobile, Desktop, Tablet } from '../utils/media-queries';


export class InlineUserInput extends Component {

    inputStyle = {
        animation : this.props.invalid
              ? css`
                  ${invalidInput} .2s ease
                `
              : '',
          maxWidth: 400,
          marginLeft: 10,
          // minWidth:400,
          width: '98%',
          borderRadius: 10,
          boxShadow: this.props.invalid ? 'inset 0 0 2px #E53935' : 'none',
          boxSizing: 'border-box',
          color: this.props.invalid ? '#E53935' : '',
          fontSize: 16,
          opacity: this.props.disabled && !this.props.invalid ? '.5' : '1',
          outline: 'none',
          padding: this.props.hasButton ? '16px 52px 16px 10px' : '16px 10px',
          'WebkitAppearance': 'none',
          borderWidth: 2,
          borderStyle: 'solid',
          backgroundImage: 'url("/assets/images/send.svg"',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '95% 50%'

    }

    validator = (value) => {
      return true;
    }

    constructor(props) {
      super(props);
      const { validator, maxLength } = props;
      if (validator) {
        this.validator = validator;
      }
      this.onBlurSubmit = this.onBlurSubmit.bind(this);
      this.state = {
        inputValue: '',
        maxLength: maxLength
      };
    }
    render(){
        const {value, placeholder, type, name, maxLength } = this.props;
        return (<div style={{display: 'flex', flexDirection: 'column', width: '95vw',}}>
          <input name={name || ''} type={type || 'textarea'} maxLength={maxLength} value={value} placeholder={placeholder} style={this.inputStyle}
        onKeyPress={this.handleKeyPress}
        onChange={this.onValueChange} 
        onBlur={this.onBlurSubmit} />
        <em style={{marginTop: 3}}>*click enter to continue</em>
        <Desktop>
        <div style={{width: 47, height: 38, backgroundColor: 'transparent', position: 'relative', float: 'right',
          marginLeft: '75%',
          zIndex: 10000000,
          top: -63}} onClick={this.onBlurSubmit}></div>
        </Desktop>
        <Mobile>
      
        <div style={{width: 47, height: 38, backgroundColor: 'transparent', position: 'relative', float: 'right',
          marginLeft: '88%',
          zIndex: 10000000,
          top: -63}} onClick={this.onBlurSubmit}></div>

        </Mobile>

        <Tablet>
        <div style={{width: 47, height: 38, backgroundColor: 'transparent', position: 'relative', float: 'right',
          marginLeft: '70%',
          zIndex: 10000000,
          top: -63}} onClick={this.onBlurSubmit}></div>
          </Tablet>
        </div>)
    }

    handleKeyPress = event => {
        if (event.key === 'Enter') {
          const {inputValue} = this.state;
          const validated = this.validator(inputValue);

          if (!inputValue.length || !validated) return false;
          const {triggerNextStep} = this.props;
          triggerNextStep({value: inputValue, sendUserMessage: true});
        }
      }

      onValueChange = event => {
        this.setState({ inputValue: event.target.value });
      };

      onBlurSubmit = event => {
        event.preventDefault()
        event.stopPropagation();
        const {inputValue} = this.state;
        const validated = this.validator(inputValue);

        if (!inputValue.length || !validated) return false;
          const {triggerNextStep} = this.props;
          triggerNextStep({value: inputValue, sendUserMessage: true});
          return false;
      }

}

