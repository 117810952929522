import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import QuoteIOSSwitch from '../switch.component';

export default function QuotePriceBlock(props) {

    const [state] = useState(props);

    const currency = (props.company === '6254dc1a-3be7-4c26-bc0e-c475b3012721') ? '€' : '$';
    

    const useStyles = makeStyles(theme => ({
        blockContainer: {
            display: 'flex',
            flexDirection: 'row',
            width: '90%',
            minHeigth: 78,
            background: '#DBF3FF',
            borderRadius: 10,
            border: '1px solid #3597EC',
            paddingTop: 15,
            paddingLeft: 15,
            marginBottom: 20,
            marginLeft: '2%',
            fontFamily: `'Open Sans', sans-serif`
        },
        unselectedBlockContainer: {
            display: 'flex',
            flexDirection: 'row',
            width: '90%',
            background: '#FFF',
            borderRadius: 10,
            border: '1px solid #3597EC',
            paddingTop: 15,
            paddingLeft: 15,
            marginBottom: 20,
            marginLeft: '2%'
        },
        serviceBlock: {
            display: 'flex',
            flexDirection: 'column',
            paddingRight: 10,
            minWidth: '70%'
        },
        priceBlock: {
            display: 'flex',
            flexDirection: 'column',
            paddingRight: 10,
            minWidth: '25%',
        },
        boldServiceText: {
            fontFamily: `'Open Sans', sans-serif`,
            fontSize: 18,
            textTransform: 'uppercase',
            fontWeight: 700
        },
        disabledServiceText: {
            fontFamily: `'Open Sans', sans-serif`,
            fontSize: 18,
            color: '#707070',
            fontWeight: 700
        },
        serviceDescription: {
            fontSize: 11,
            marginBottom: 20
        },
        perVisit: {
            fontSize: 12,
            color: '#ccc',
            lineHeight: 1
        }
      }));

    const classes = useStyles()
    
    let obj = props.pricing.filter(i => i.frequencyType === 'application')
    let obj2 = props.pricing.find(i => i.frequencyType === 'application');
    let hasOffers = props.offers.filter(i => !i.hide).length ? true : false;
    let offerText = '';
    let offer = {};

    const displayLabel = props.applicationLabel.charAt(0).toUpperCase() + props.applicationLabel.slice(1)

    if (hasOffers) {
        let regular = ''
        offer = props.offers[0];
        const off = offer.appliedTo === 'first-app' ? '1st ' + displayLabel : `remaining ${props.applicationLabel}s`;
        let amount = offer.discountType === 'percentage-off' ? `${offer.discountAmount}% off`: `${currency}${offer.discountAmount} for the`;
        
        if (obj2) {
            if (offer.discountType === 'discounted-price-table')  {
                amount =  `${currency}${obj2.servicePrice} for the`;
                regular = offer.appliedTo === 'first-app' ?
                            `${currency}${obj2.regularApplicationCost} for each remaining ${props.applicationLabel}.` 
                            : `Normally ${currency}${obj2.regularApplicationCost} per ${props.applicationLabel}.`
            } else {
                regular = offer.appliedTo === 'first-app' ?
                            `${currency}${obj2.regularApplicationCost} for each remaining ${props.applicationLabel}.` 
                            : `Normally ${currency}${obj2.regularApplicationCost} per ${props.applicationLabel}.`
            }
        }
        
        offerText = `*${amount} ${off}. ${regular}`
    }

    
    let price = 'NA';
    let frequency = 'NA';
    // let remaining = 'NA';

    if (!obj.length  || props.term === 'total') {
        obj = props.pricing.filter(i => i.frequencyType === 'total');
        if(obj.length) {
            obj = obj[0];
            price = obj.servicePrice;
            frequency = obj.frequencyPeriod;
            // remaining = obj.remainigPeriods;

        }
    } else {
        obj = obj[0];
        price = obj.servicePrice;
        frequency = 'per ' + ( displayLabel || 'application' );
      
        if (hasOffers && offer.appliedTo === 'first-app') {
            frequency = `1st ${displayLabel}`;
        }

        if (props.autoPayPriceMethod === 'monthly-payment') {
            frequency = `per Month`;
        }
        // remaining = obj.remainigPeriods;
    }
    
   

    return (<div className={props.selected ? classes.blockContainer : classes.unselectedBlockContainer}>
        <div className={props.selected ? classes.serviceBlock : `${classes.disabledServiceText} ${classes.serviceBlock}`}>
            <div className={classes.boldServiceText}>{props.serviceName || 'WEEKLY MOWING'}</div>
            <div className={classes.serviceDescription}>
            {props.serviceDescription || `Cutting, edging & blowing off all hard 
surfaces every 7-10 days`}<br/><br/>
<span style={{fontWeight: 'bold', fontSize: 13}}>{props.applicationsRemaining} {displayLabel}{props.applicationsRemaining > 1 ? 's' : ''} {props.seasonalText}</span>
            </div>
        </div>
        <div className={classes.priceBlock}>
            <div className={props.selected ? classes.boldServiceText : classes.disabledServiceText }>
                {currency}{price ? price.toFixed(2) : '0'}</div>
    <div className={classes.perVisit}>{frequency || 'total'}</div>
        <QuoteIOSSwitch {...state} />
        <p style={{
            fontSize: 10,
            fontWeight: 600,
            fontWeight: 'bold',
            color: 'red'
        }}>{offerText}</p>
        </div>
        
    </div>)
}
