import React from 'react';
import { HtmlMessageComponent } from '../components/html-message.component';
import { InlineUserInput } from '../components/inline-user-input.component';
import { RedirectMessageComponent } from '../components/redirect-message.component';

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


export default function welcomeSteps(self) { 
    return [
    {
        id: '1',
        inputAttributes:{html: self.greeting ? self.greeting : `Welcome to <b>${self.companyName}</b> Instant Quote™
        
        <br><br>
        📏 <b>We Can Measure Your Property</b><br>
        👨🏻‍🔬 <b>Analyze Your Service Area</b><br>
        👆🏻 <b>Tailor A Custom Program</b><br>
        📝 <b>Deliver An Instant Quote</b><br>
        <br><br>
        Let's Get Started Below To Begin👇
        `},
        component: (<HtmlMessageComponent />),
        asMessage: true,
        trigger: () => {
        if (self.state.phonenumber) {
            return 'welcomeback';
        } else {
            return '2';
        }
        },
    },

    {
        id: '2',
        options: [
        { value: 1, 
            label: 'Let\'s Get Started', 
            text: '⚡',
            textStyle: {fontSize: 20, color: 'yellow'},
            color: "yellow" , 
            trigger: () => {
        // return 'get-started'
         return 'new-customer-phone-ask'
            // return 'fertnweed-entry';
        } },
        { value: '2', 
        label: 'Learn More', 
        trigger: () => {return 'learn-more'}, 
        textStyle: {fontSize: 20, color: 'yellow'},
        text: '🔎',
        color: "yellow"}],
        optionType: 'default',
    },

    {id: 'welcomeback',
        message: () => {
            return `${self.salutation} ${self.state.customerName.trim().split(" ")[0]}!`;
        },
        trigger: () => {
            if (self.redirectToPortal) {
                return 'message-redirect-to-portal'
            }

            if (self.state.customerLat !== 0) {
                return 'show-customer-aerial';
            } else {
                const { noPII, adjustedLotSize } = self.state;
                const max = self.companyMaxQuotableSize;
                const min = self.companyMinQuotableSize;
                if (adjustedLotSize > max || adjustedLotSize < min) {
                    return 'sqft-too-big';
                } else {
                    if (noPII) {
                        return 'ask-email';
                    } else {
                        const hasNull = self.zipCodes.filter(i => i === 'null').length;
                        const zipcodeMap = self.zipCodes
                                      .filter(i => (i !== 'null' && i !== "") )
                                      .map(i => `${i}`.toLowerCase())
                                      .filter(i => {
                                        return `${self.state.customerZip}`.toLowerCase().indexOf(i) !== -1
                                      });
                        
                        if (!zipcodeMap.length && !hasNull) {
                            return 'zip-not-serviced';
                        }
                      
                        return 'customer-confirm-address'
                    }
                }
            }
            
        }
    },

    {id: 'show-customer-aerial',
    inputAttributes: {html: () => { return `<img width="100%" src="${self.state.aerialImg}" />`; }},
    component: (<HtmlMessageComponent />),
    asMessage: true,
    trigger: () => {
        if (self.state.streetImg) {
            return 'show-customer-streetview'
        } else {
            const { noPII, adjustedLotSize } = self.state;

            const max = self.companyMaxQuotableSize;
            const min = self.companyMinQuotableSize;
            if ((adjustedLotSize > max || adjustedLotSize < min) && self.sqftEstimateSource !== 'measur-it') {
                return 'sqft-too-big';
            } else {
                if (noPII) {
                    return 'ask-email';
                } else {
                    const hasNull = self.zipCodes.filter(i => i === 'null').length;
                    const zipcodeMap = self.zipCodes
                                      .filter(i => (i !== 'null' && i !== "") )
                                      .map(i => `${i}`.toLowerCase())
                                      .filter(i => {
                                        return `${self.state.customerZip}`.toLowerCase().indexOf(i) !== -1
                                      });
                                     
                        if (!zipcodeMap.length && !hasNull) {
                            return 'zip-not-serviced';
                        }
                    return 'customer-confirm-address'
                }
            }
        }
        
    }
    },

    {id: 'show-customer-streetview',
    inputAttributes: {html: () => { return `<img width="100%" src="${self.state.streetImg}" />`;}},
    component: (<HtmlMessageComponent />),
    asMessage: true,
    trigger: () => {
        const { noPII, adjustedLotSize } = self.state;
        const max = self.companyMaxQuotableSize;
        const min = self.companyMinQuotableSize;
        if ((adjustedLotSize > max || adjustedLotSize < min) && self.sqftEstimateSource !== 'measur-it') {
            return 'sqft-too-big';
        } else {
            if (noPII) {
                return 'ask-email';
            } else {
                const hasNull = self.zipCodes.filter(i => i === 'null').length;

                const zipcodeMap = self.zipCodes
                                      .filter(i => (i !== 'null' && i !== "") )
                                      .map(i => `${i}`.toLowerCase())
                                      .filter(i => {
                                        return `${self.state.customerZip}`.toLowerCase().indexOf(i) !== -1
                                      });
                                      
                        
                        if (!zipcodeMap.length && !hasNull) {
                            return 'zip-not-serviced';
                        }
                return 'customer-confirm-address'
            }
        }
    }
}   ,

    {id: 'customer-confirm-address',
    inputAttributes: {html: () => { return `You are looking for a quote for your home located at <br><br><b>${self.state.customerAddress}</b><br><br>Correct?`; }},
    component: (<HtmlMessageComponent />),
    asMessage: true,
    trigger: 'customer-confirm-address-options'
    },

    {id: 'customer-confirm-address-options',
    options: [{value: 'yes', label: 'Yes', trigger: 'company-value-add-entry', text: '👍'}, {value: 'no', label: 'No', trigger: 'not-my-address', text: '👎'}],
    optionType: 'default',  
    },
    {id: 'ask-email', message: `What's the best email to reach you at?

    (Example: jsmith@gmail.com)`, trigger: 'get-email'},
    {id: 'get-email', component: (<InlineUserInput placeholder={'jsmith@gmail.com'} type={'email'}
            validator={(value) => {
                return validateEmail(value);
            } }/>),
    waitAction: true,
    sendUserMessage: true,
    dispatchMessage: (value) => {
        if (value && value !== 'null') {
            self.setState({customerEmail: value});
        }
        
     },
    trigger: 'ask-name'},
    {id: 'ask-name', message: 'Who do I have the pleasure of speaking to?', trigger: 'get-name'},
    {id: 'get-name', component: (<InlineUserInput placeholder={'John Smith'} />),
    waitAction: true,
    sendUserMessage: true,
    dispatchMessage: async (value) => {
        if (value && value !== 'null') {
            self.setState({customerName: value});
            setTimeout(async() => {
                const quote_id = await self.startChatRecord();
    
                self.setState({chatId: quote_id});
            },500)
            
        }
     },
    trigger: 'company-value-add-entry'},
    {id: 'measure-lawn', message: 'Thank you. While I’m measuring your property, I just need you to answer some questions so I can build the best program…', trigger: 'estimate-tada'},
    {id: 'estimate-tada', component: (<HtmlMessageComponent />), 
    inputAttributes: {html: "<b>I finished measuring your property (ta-da!)</b>"} , 
    asMessage: true,  
    trigger: () => {
        if (self.state.adjustedLotSize === 0 || isNaN(self.state.adjustedLotSize) || typeof self.state.adjustedLotSize === 'undefined') {
            return 'well-thanks-anyway';
        } else {
            if (self.state.measuritImg) {
                return 'display-measurit-image';
            } else {

                self.recordEstimate(self.state.adjustedLotSize, 'sqft', null);
                return 'display-estimate';
            }
        } 
    } },
    {id: 'display-measurit-image' , component: (<HtmlMessageComponent />),
            inputAttributes: {html: `<img src=${self.state.measuritImg} />`}, 
            asMessage: true, trigger: 'you-colored-lawn-size'},
    {id: 'display-estimate', message: () => {return `Making sure not to include the house or driveway, we measured your property to be ${self.state.adjustedLotSize} sq ft.`}, trigger: 'intro-program'},
    {id : 'company-value-add-entry', message: () => { return self.companyValueDescription || "COMPANY VALUE ADD: this step should have been replaced via api"}, trigger: 'company-value-add-image'},
    {id: 'company-value-add-image', asMessage: true, component: (<HtmlMessageComponent />), inputAttributes: {html: () => { return `<img src="${self.companyValueAddImage}" width="100%" />`;}} 
    , trigger: () => {
        if (self.sqftEstimateSource === 'measur-it') {
            return 'intro-program'
        }
        return 'estimate-tada' ;    
    }
    },
    
    {id: 'match-program-message', message: "In order to match you with the perfect program", trigger: 'intro-program'},
    {
        id: 'measur-it-okay-great', message: 'Okay great…', trigger: 'lets-measure-your-lawn-together'
    },
    { id: 'now-opening-quote', message: 'Now opening your quote', end: true},
    {
        id: 'lets-measure-your-lawn-together',
        message: "Now let’s go ahead and measure your property together!",
        trigger: 'now-opening-measur-it'
    },
    {
        id: 'now-opening-measur-it',
        message: () => {
            const s = self;
            setTimeout(() => {
                s.setState({estimateModalOpened: true});
            }, 3000);

            let action = 'color';
            if (self.measurementMode === 'perimeter') {
                action = 'outline';
            } 

           return  `Opening our Measur.it technology… ${action} in the areas that you want serviced this season.`
        },
        end: true,
    },

    {
        id: 'you-colored-lawn-size', message: () => {

            let label = "sq ft";
            let action = 'colored';
            if (self.measurementMode === 'perimeter') {
                action = 'outlined';
                if (self.isMetric) {
                    label = "linear m";
                } else {
                    label = "linear ft"
                }
            } else {
                if (self.isMetric) {
                    label = "sq m"
                } else {
                    label = "sq ft"
                }
            }
            // self.recordChatRecord({adjustedLotSize: self.adjustedLotSize})
            return `Awesome... Looks like you ${action} in ${self.adjustedLotSize} ${label} to be serviced.`
        },
        trigger: () => {
            console.log(self.adjustedLotSize);
            console.log(self.companyMinQuotableSize, self.companyMaxQuotableSize);
            if ((self.adjustedLotSize < self.companyMinQuotableSize) || 
             (self.adjustedLotSize > self.companyMaxQuotableSize)) {
                return 'well-thanks-anyway';
             } else {
                const {selectedPrograms, steps} = self.state;
        
                if (selectedPrograms.indexOf("Irrigation Services") !== -1) {
                    const dest = self.startSericeQuestions();
                    return dest;
                } 

                const questions = selectedPrograms.map(i => {
                   const entry = self.getSericeEntryId(i) + '-entry';
                   const item = steps.find(t => t.id === entry);
                   return item;
                }).filter(p => p);

                if (self.preModalQuestions.length) {
                    return 'pre-modal-entry';
                  }

                if (questions.length && !self.skipProbingQuestions) {
                    return 'ask-quote-now';
                } else {
                    const t = self.updateCalculatingStep();
                    return 'no-questions-open-quote';
                }
             }
        
        }
    },

    {id: 'no-questions-open-quote', message: "We will now recommend the best possible programs.", trigger: 'get-started'},

    {id: 'confirm-lot-size', options: [{value: 'Yes', label: 'Yes', trigger: 'intro-program', text: '👍'}, {value: 'No', label: 'No', trigger: 'sqft-slider', text: '👎'}], optionType : 'default'},

    {id: 'ready-question',
        message: 'Ready to get your quote?',
        trigger: 'do-it'},

    {id: 'do-it',
    options: [{value:1, label: 'Yes let\'s do it!', trigger: 'new-customer-phone-ask'}],
    optionType: 'default'},

    {id : 'message-redirect-to-portal',
    asMessage : true,
    inputAttributes: {
        url: () => { return self.crmPortalUrl },
        timeout: 3000,
        target: "_top",
        html: () => { return `Our records show that this address is active in our database... Now directing you to our customer portal where you can manage your account online.. (redirecting now...)`; }},
    component: (<RedirectMessageComponent />),
    end: true
    }
    
] };