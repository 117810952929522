import React, { Component } from 'react'
import PropTypes from 'prop-types'

class SecureField extends Component {
  render() {
    const { fieldType, label, customClass, callback, children } = this.props

    return (<div id={fieldType + '-number-container'} className={customClass} style={this.props.style || {}} onClick={(e) => callback(e)}>
      <label htmlFor={'pci-' +fieldType + '-number'}>{label}</label>
      <div className="secure-field--input-container" style={{height: '40px'}}>
        <div className="secure-field--input" id={'pci-'+ fieldType + '-number'} style={{width: fieldType === 'cvv' ? 70 : '130%'}}></div>
        <div className="secure-field--actions">
          <span className={'secure-field--action secure-field--action__' + fieldType + '-icon'}>
            <span className={'secure-field--action-' + fieldType + '-wrap'}>
              {children}
            </span>
          </span>
        </div>
      </div>
    </div>)
  }
}

SecureField.propTypes = {
  fieldType: PropTypes.string,
  label: PropTypes.string,
  customClass: PropTypes.string,
  callback: PropTypes.func,
  children: PropTypes.object
}

export default SecureField
