import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function PlanSwitch(props) {

    const activeState = props.term === 'application' ? 'select-button-active.svg' : 'select-button.svg'
    const [btnState, setBtnState] = useState(activeState)


    
      const handleChange = name => {
       
        if (name === 'prepay') {
          setBtnState('select-button-active.svg')
          props.updatePricingTerms('total');
        } else {
          setBtnState('select-button.svg')
          props.updatePricingTerms('application');
        }
      };
      

    return !isNaN(props.discount) && (props.autoPayAvailable && props.prePayAvailable )? ( <div style={{width: 349,
      height:63,
     backgroundImage: `url(/assets/images/${btnState})`,
     backgroundSize: '86%',
     backgroundRepeat: 'no-repeat',
     backgroundPosition: 'center',
     display: 'flex',
     flexDirection: 'row',
     justifyContent: 'space-around',
     alignContent: 'center',
     fontFamily: `'Open Sans', sans-serif`,
     cursor: 'pointer' }} >
      <div onClick={() => {handleChange('autopay'); }} style={{paddingTop: 20, fontSize: 11, marginLeft: 33}}>{props.autoPayAlternativeLabel || 'AUTOPAY'}</div>
      <div onClick={() => {handleChange('prepay'); }} style={{paddingTop: 20, fontSize: 11, marginRight: 25}}>PRE-PAY {(props.discount).toFixed(1) !== "0.0" ? `& SAVE $${props.discount.toFixed(0)}`: '' }</div>  
      </div>) : null;
}

PlanSwitch.propTypes = {
  updatePricingTerms: PropTypes.func
}