import React,  { Component } from 'react';
import Rating from 'react-rating';


export default class RatingWidget extends Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.selectRating = this.selectRating.bind(this);

        this.state = {
            value: props.placeholderRating || 3
        }
    }

    handleClick(event) {
        this.setState({value: undefined});
      }

    selectRating(rating) {
        const { triggerNextStep } = this.props;
       
        triggerNextStep({value: (rating) + '', sendUserMessage: true});
    }

      SVGIcon(icon) {
          const style={
            width: 50,
            height: 50
          }
            return (<div style={style}><img src={icon.href} alt="star" style={{color: 'yellow'}} /></div>);
      }

      render() {
        return (
          <div>
            <Rating
               emptySymbol={this.SVGIcon({className: 'empty-star', href: '/assets/images/star.svg'})}
               fullSymbol={this.SVGIcon({className: 'full-star', href: '/assets/images/star-filled.svg'})}
                fractions={4}
                onChange={this.selectRating}
                />
          </div>
        );
      }
}