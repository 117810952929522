import React from 'react';
import { makeStyles } from '@material-ui/core';
import * as _ from 'lodash';

export default function PriceSummary(props) {

    const price = props.term === 'total' ? props.allTotal : props.firstTotal;
    const taxAmount = price * (props.serviceTax / 100) ;
    const totalPrice = price + taxAmount;

    const currency = (props.company === '6254dc1a-3be7-4c26-bc0e-c475b3012721') ? '€' : '$';
    
    let serviceLabel = props.term === 'total' ? 'TOTAL SERVICE AMOUNT' : `GETTING STARTED AMOUNT`;
    if (props.autoPayPriceMethod === 'monthly-payment' && props.term !== 'total') {
        serviceLabel = "FIRST MONTH'S PAYMENT";
    }
    

    const useStyles = makeStyles(theme =>({
        container: {
            background: '#F4F4F4',
            fontFamily: `'Open Sans', sans-serif`,
            fontSize: 13,
            color: '#000000',
            width: '100%',
            Minheight: 133,
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 25,
            paddingBottom: 25,
        },
        serviceRow: {
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            paddingTop: 15
        },
        serviceItem: {
            flex:1,
            textAlign: 'center',
 
        },
        discountRow: {
            color: 'red',
            fontWeight: 'bold'
        },
        mediumPrice: {
            fontSize: 21,
            lineHeight: 1.0
        },
        bigPrice: {
            fontSize: 32,
            lineHeight: 0.3
        },
        hidden: {
            display: 'none'
        }
    }));

    const classes = useStyles();


    const displayOffers = () => {
        const pboffers = props.priceBlocks.filter(i => i.selected).map(i => {
            let offers = i.offers.filter(o => !o.hide).map(j => {
                j.serviceName = i.serviceName;
                j.applicationLabel = i.applicationLabel;
                const pricing = i.pricing.find(t => t.frequencyType === 'application');
                if (pricing) {
                    j.servicePrice = pricing.servicePrice;
                }
                return j;
            });
            return offers;
        });
        const serviceoffers = props.availableServices.filter(i => i.selected).map(i => {
            let offers = i.offers.filter(o => !o.hide).map(j => {
                j.serviceName = i.serviceName;
                j.applicationLabel = i.applicationLabel;
                const pricing = i.pricing.find(t => t.frequencyType === 'application');
                if (pricing) {
                    j.servicePrice = pricing.servicePrice;
                }
                return j;
            });
            return offers;
        });
        let offers = [...pboffers, ...serviceoffers];

        offers = _.flattenDeep(offers);

        if (!offers.length) return null;
        return (<div style={{marginBottom: 10, marginLeft: 5,
            marginTop: 10}}>
            <span style={{
                fontSize: 12,
                marginLeft: 28,
                textDecoration: 'underline',
                fontWeight: 600
            }}>Discounts</span>
            {offers.map(i => {
                const displayLabel = i.applicationLabel.charAt(0).toUpperCase() + i.applicationLabel.slice(1)
                let offerText = ""
                if (i.appliedTo === "first-app") {
                    if (i.discountType === 'percentage-off') {
                        offerText = `1ST ${displayLabel.toUpperCase()} ${i.discountAmount}% OFF`;
                    } else {
                        offerText = `1ST ${displayLabel.toUpperCase()} ONLY $${i.discountAmount}`
                    }
                } else {
                    if (i.discountType === 'percentage-off') {
                        offerText = `ENTIRE PROGRAM ${i.discountAmount}% OFF`;
                    } else {
                        offerText = `ENTIRE PROGRAM $${i.discountAmount} PER ${displayLabel.toUpperCase()}`
                    }
                }

                if (i.discountType === 'discounted-price-table') {
                    if (i.appliedTo === "first-app") {
                        offerText = `1ST ${displayLabel.toUpperCase()} ONLY $${i.servicePrice}`
                    } else {
                        offerText = `ENTIRE PROGRAM $${i.servicePrice} PER ${displayLabel.toUpperCase()}`;
                    }
                }
                return (
                    <div className={`${classes.serviceRow}`} style={{paddingTop: 5}}>
                        <div className={[classes.serviceItem, classes.discountRow].join(",")} style={{
                            textAlign: 'left',
                            fontSize: 11,
                            fontStyle: 'italic',
                            paddingLeft: 30,
                            fontWeight: 'bold',
                            color: 'red'
                        }}>
                                - {i.serviceName} {offerText}
                    </div></div>);
            })}</div>);
      
    }


    const displayServiceTax = () => {
        if (props.serviceTax === 0.0) return null;
        return (
            <div className={`${classes.serviceRow}`}>
            <div className={classes.serviceItem}>Service Tax ({props.serviceTax.toFixed(3)}%)</div>
            <div className={`${classes.serviceItem}`}>{currency}{taxAmount.toFixed(2)}</div>
        </div>
        ) 
    }



    return (<div className={classes.container}>
        <div className={`${classes.serviceRow}`}>
            <div className={classes.serviceItem}>{serviceLabel}
            {props.autoPayPriceMethod === 'monthly-payment' && props.term !== 'total' ? ( <span className="note" 
            style={{
                color: 'blue',
                fontSize: 11,
                marginLeft: 20,
                display: 'block'
            }}>
            * payments spread over {props.autoPayMonthlyPricePeriod} months.
            </span>): null}</div>
            <div className={`${classes.serviceItem}`}>{currency}{price.toFixed(2)}</div>
        </div>
        {displayOffers()}
       {displayServiceTax()}
        <div className={`${classes.serviceRow}`}>
    <div className={classes.serviceItem}>{props.term === 'total' ? `PRE-PAY AND SAVE`: 'GET STARTED TODAY FOR'}</div>
    <div className={`${classes.serviceItem} ${classes.bigPrice}`}>{currency}{totalPrice.toFixed(2)}</div>
        </div>
    </div>)
}