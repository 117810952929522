import React, {Component} from 'react';
import * as fetch from 'fetch';

import PropTypes from 'prop-types';

export class CalculatingQuoteComponent extends Component {

    ga = null;

    loadingStyle = { width: '100%'}
    constructor(props) {
        super(props);
        const loader = props.loadingGif || 'standard';

        let limage = 'analyizing-loader.gif';

        switch(loader) {
            case 'standard':
                limage = 'analyizing-loader.gif';
                break;
            case 'no-soil':
                limage = 'analyizing-loader-no-soil.gif';
                this.loadingStyle = {maxHeight: 220}
                break;
            case 'pest-control':
                limage = 'pest-control-loader.gif';
                break;
            default:
        }

        this.state = {
            loaderTimeoutFinished: false,
            callbackFinished: false,
            loaderImage: limage
        }
    }

    componentDidMount() {
        const { triggerNextStep } = this.props;
        this.getQuotePriceBlocks();
        // this.getQuotePriceBlocks();
        // this.getQuotePriceBlocks();
        setTimeout(() => {
            triggerNextStep({value: 'Opening Quote Now'});
        }, 7500);
    }

    getQuotePriceBlocks() {
        const { selectedServices, answers, companyId, botId, apiURL, totalLotSize, adjustedLotSize, chatId, customerZip} = this.props;
        const payload = {
            method: "POST",
            mode: "no-cors",
            cache: "no-cache",
            headers: {
            "Content-Type": "application/json"
            },
            payload: JSON.stringify({
                serviceTypes: selectedServices,
                companyId,
                quoteId: chatId,
                botId,
                estimate: adjustedLotSize,
                answers,
                postalCode: customerZip
            })
        }
        const buster = '?buster=bust_' +  Math.floor(Math.random() * 100000);

        fetch.fetchUrl(apiURL + `/v1/quote/services`, payload , (error, meta, body) => {
            const statusCode = meta.status;
            if (statusCode === 404) {
                this.props.bot.moveToStep('sqft-too-big');
            } else {
            if (this.props.ga) {
                this.props.ga.send('event', 'quote', 'given', 1);
            }
            const str_body = body.toString();
          
            let jsonBody = {};
            try {
                jsonBody = JSON.parse(str_body);
                const { setPriceBlocks } = this.props;
                const ontop = jsonBody.filter(i => i.alwaysOnTop);
                const notOnTop = jsonBody.filter(i => !i.alwaysOnTop);
                const all = [...ontop, ...notOnTop];
                setPriceBlocks(all);

            } catch(e) {
                console.log(e);
                this.props.bot.current.moveToStep('sqft-too-big');
            }
            }
        });
    }

     

    render() {
      return (
      <div style={{width: '80%', fontFamily: "'Open Sans', sans-serif", fontSize: 16, margin: 'auto', 
      paddingBottom: 50, textAlign: 'center', minHeight: 200, color: 'rgb(20, 126, 251)' }}>
          <img src={`/assets/images/${this.state.loaderImage}`} alt="calculating" style={this.loadingStyle}/>
      </div>
      );
    }
  }

  CalculatingQuoteComponent.propTypes = {
    setPriceBlocks: PropTypes.func
  }