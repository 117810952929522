import React from 'react';
import { InlineUserInput } from '../components/inline-user-input.component';
import { HtmlMessageComponent } from '../components/html-message.component';

export default function newCustomerOnboard(self) {

    return [
      {id: 'new-customer-intro',
        message: "Hi my name is Kim... Who do I have the pleasure of speaking with?",
        trigger: 'new-customer-collect-name'
        },
        {id: 'new-customer-collect-name',
        component: (<InlineUserInput placeholder={'John Smith'} />),
        dispatchMessage: (val) => {
          if (val && val !== 'null') {
            self.setState({customerName: val});
            // self.recordChatRecord({customerName: val});
          }
        },
        waitAction: true,
        trigger: 'new-customer-welcome'
        },
        {id: 'not-my-address',
        message: "Okay well then let me ask you some questions to make sure we get you the most accurate quote possible.",
        trigger: 'no-address-phone-ask'
        },
        {id: 'new-customer-welcome',
        message: "Wonderful {previousValue}!  Thanks so much for interating with our bot",
        trigger: 'new-customer-address-ask'
        },
        {id: 'new-customer-address-ask',
        message: "What is the street address of the property which you would like a quote?",
        trigger: 'new-customer-address-collect'
        },
        {id: 'new-customer-address-collect',
        user: true,
        placeholder: 'Enter Street Address',
        trigger: 'new-customer-city-state-ask'
        },
        {id: 'new-customer-city-state-ask',
        message: "What is the city and state of the property?",
        trigger: 'new-customer-city-state-collect'
        },
        {id: 'new-customer-city-state-collect',
        user: true,
        placeholder: 'Enter City, State',
        trigger: 'new-customer-lookup-start'
        },
        {id: 'new-customer-lookup-start',
        message: "Perfect!  We are now going to attempt to collect data on your property...",
        trigger: 'get-started'
        },
        {id: 'new-customer-phone-ask',
          message: `Wonderful!  To start your Instant Quote
      
          please enter your complete phone number.`,
        trigger: 'new-customer-phone-collect'},
        {id: 'no-address-phone-ask',
        message: `I can look up your Instant Quote
    
        please enter your complete phone number.`,
      trigger: 'new-customer-phone-collect'},
      
        {id: 'new-customer-phone-collect',
        component: (<InlineUserInput placeholder={'6165551212'} type={'tel'} name={'phone'} maxLength={11} validator={
          (value) => {
          const check = (value + '').length;

          if (isNaN(value) || (check !== 10 && check !== 11)) {
            return false;
          }
          return true;
        }

        } />),
        waitAction: true,
        sendUserMessage: true,
        dispatchMessage: (value) => {
          if (value && value !== 'null') {
            self.setState({phonenumber: value});
            // self.recordChatRecord({phonenumber: value});
            self.lookupCustomer(value);
          }
        },
        trigger: 'looking-up-record'},
        {id: 'looking-up-record',
        message: 'Please wait while we check our records',
        trigger: () => {
          if (self.state.customerName) {
            return 'welcomeback'
          } else {
            return 'didnt-find-record'
          }
        } 
      },
      {id: 'didnt-find-record',
      message: 'We couldn\'t find a record with your phone number.  That\'s okay... let me collect some information from you.',
      trigger: 'new-customer-zip-ask'
      },
      
          {id: 'new-customer-zip-ask',
          message: `Please enter your postal code`,
          trigger: 'new-customer-zip-collect'},
      
      
          {id: 'new-customer-zip-collect',
          component: (<InlineUserInput placeholder={'Postal Code (49546, T2N 0H4)'} name={'zip'} type={'text'} validator={
            (value) => {
            if ( !/[\d\w]{3}\s*[\d\w]{2,3}/ig.test(value + '')) {
              return false;
            }
            return true;
          }
          } />),
          waitAction: true,
          sendUserMessage: true,
          dispatchMessage: (value) => {
            
          },
          trigger: (previousValue) => {
            const value = `${previousValue.value}`.toLowerCase();
            const hasNull = self.zipCodes.filter(i => i === 'null').length;
            const zipcodeMap = self.zipCodes
                                      .filter(i => (i !== 'null' && i !== "") )
                                      .map(i => `${i}`.toLowerCase())
                                      .filter(i => {
                                        return `${value}`.indexOf(i) !== -1
                                      });

            
            if (!zipcodeMap.length && !hasNull) {
              return 'zip-not-serviced';
            } else {
              if (value && value !== 'null')
              self.setState({customerZip: value});
              // self.recordChatRecord({customerZip: value});
              self.lookupZipCode(value);
              if (self.lookupZipCodeTax) {
                self.lookupPostalTaxRate(value);
              }
              return 'new-customer-address-ask';
            }
           
          }},
          {id: 'new-customer-address-ask',
          message: `Great! We have many customers in {previousValue}.
      
          Please enter your street address
           
          
          Example. (7890 Main St) `,
          trigger: 'new-customer-address-collect'},
      
      
          {id: 'new-customer-address-collect',
          component: (<InlineUserInput placeholder={'Enter Street Address'} name={'address'}/>),
          waitAction: true,
          sendUserMessage: true,
          dispatchMessage: (value) => {
             if (value && value !== 'null') {
                self.setState({customerAddress: value});
                // self.recordChatRecord({customerAddress: value});
                self.lookupEstimateFromSources({customerAddress: value}); 
             }
            
          },
          trigger: 'looking-up-property'},
      
          {id: 'looking-up-property',
          message: `We are looking up information on your property... please wait.`,
          trigger: () => {
            const { totalLotSize } = self.state;
            if (self.sqftEstimateSource === 'measur-it') {
  
                return 'show-customer-aerial';
    
            } else {
              if (totalLotSize ) {
                return 'show-customer-aerial';
              } else {
                return 'well-thanks-anyway';
              }
            }

            
          }
          },
          {id: 'congrats-sale', message: 'Congratulations!', trigger: () => {
            self.purchaseMade = true;
            return 'sales-party';
          }},
          {id: 'sales-party',
          component: (<HtmlMessageComponent />),
          inputAttributes: {html: "<img src='/assets/images/party.gif' alt='congrats on sale' width='100%' />"},
          trigger: 'service-questions-entry'
          },
          {id: 'zip-not-serviced',
          message: "It appears we do not service your area. Do you have any questions for our team?",
          trigger: "get-comments"
          },
          {id: 'sqft-too-big',
          message: "After reviewing your property, we feel it would be best for us to quote your property by phone or in-person.  Do you have any questions for our team?",
          trigger: "get-comments"
          },
      
      ]


}